import React, { createContext, useState, useContext } from 'react';
import video1 from '../assets/videos/mvm-lvideo.mp4';

const SelectingVideoContext = createContext();

export const SelectingVideoProvider = ({ children }) => {
    const [selectedVideo, setSelectedVideo] = useState("");

    return (
        <SelectingVideoContext.Provider value={{ selectedVideo, setSelectedVideo }}>
            {children}
        </SelectingVideoContext.Provider>
    );
};

export const useSelectingVideo = () => useContext(SelectingVideoContext);
