import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLoading } from '../contexts/LoaderContext';

const ScrollContext = createContext();

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
    const [scrollTo, setScrollTo] = useState(null);
    const { isLoading } = useLoading();

    useEffect(() => {
        if (!isLoading) {
            if (scrollTo) {
                const section = document.getElementById(scrollTo);
                if (section) {
                    const sectionRect = section.getBoundingClientRect();
                    const offset = 90;
                    const scrollToPosition = sectionRect.top + window.scrollY - offset;
                    window.scrollTo({
                        top: scrollToPosition,
                        behavior: 'smooth'
                    });
                    // section.scrollIntoView({ behavior: 'smooth' });
                    setScrollTo(null);
                }
            }
        }

    }, [scrollTo, isLoading]);

    return (
        <ScrollContext.Provider value={{ setScrollTo }}>
            {children}
        </ScrollContext.Provider>
    );
};
