import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/about.css'; // Create this file for custom styles

// Import your icons here
// import aboutUsImage from '../../assets/images/about-us.png'; // Replace with your image path
// import aboutUsImage from '../../assets/images/abou-us-2.jpg';
import aboutUsImageMobileScreens from '../../assets/images/about-us-3jpg.jpg'
import aboutUsImageWideScreens from '../../assets/images/production33.jpg';
import icon1 from '../../assets/images/about-us-icon1.png'; // Replace with your icon path
import icon2 from '../../assets/images/about-us-icon2.png'; // Replace with your icon path
import icon3 from '../../assets/images/about-us-icon3.png'; // Replace with your icon path

import { useTranslation } from 'react-i18next';

function AboutUs({aboutUsWebImage, aboutUsMobileImage}) {
    const [aboutBg, setAboutBg] = useState(aboutUsImageWideScreens)
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (window.innerWidth < 768 && !aboutUsMobileImage)
            setAboutBg(aboutUsImageMobileScreens);
        else if (window.innerWidth < 768 && aboutUsMobileImage)
            setAboutBg(aboutUsMobileImage);
        else {
            if (aboutUsWebImage)
                setAboutBg(aboutUsWebImage);
            else
                setAboutBg(aboutUsImageWideScreens);
        }
    }, [])
    return (
        <section id="about" style={{ overflow: "hidden" }}>
        <Container className="about-us-section text-center" fluid>
            <Row className="about-us-row align-items-center">
                <Col xs={12} className="about-us-image p-0">
                    <div className='animated-circle-text'>REC</div>
                    <div className='animated-circle'></div>
                    {/* <Image className='about-us-img' width={'100%'} src={aboutUsImage} fluid style={{objectFit: 'cover' }}/> */}
                    <div className='about-us-img' style={{ backgroundImage: `url(${aboutBg})`}}></div>
                </Col>
                <Col xs={12} className="about-us-content mt-5 mb-md-5 mb-3" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="title-section">
                        <div className="line mb-4"></div>
                        <h2 className={`${i18n.language === 'ar' ? "section-title-ar" : 'section-title'} mb-2`}>{t('HomePage.AboutUs.title')}</h2>
                    </div>
                    <Row className="info-row justify-content-center mb-4" dir='ltr'>
                        <Col xs={5} md={3} className="info-item">
                            <Row>
                                <Col className="col-md-1 col-12">
                                <img src={icon1} alt="Motion Visual Media" className="info-icon me-2" />
                                </Col>
                                <Col>
                                <span className="info-text">{t('Motion Visual Media')}</span>
                                </Col>
                            </Row>
                            {/* <div>
                            <img src={icon1} alt="Motion Visual Media" className="info-icon me-2" />
                                <span className="info-text">Motion Visual Media</span>
                            </div> */}
                        </Col>
                        <Col xs={2} md={3} className="info-item">
                            <Row>
                                <Col className="col-md-1 col-12">
                                <img src={icon2} alt="MVM" className="info-icon me-2 person-info-icon" />
                                </Col>
                                <Col>
                                <span className="info-text">{t('MVM')}</span>
                                </Col>
                            </Row>
                            {/* <div>
                                <img src={icon2} alt="MVM" className="info-icon me-2 person-info-icon" />
                                <span className="info-text">MVM</span>
                            </div> */}
                        </Col>
                        <Col xs={5} md={3} className="info-item">
                            <Row>
                                <Col className="col-md-1 col-12">
                                <img src={icon3} alt="Date" className="info-icon me-2" />
                                </Col>
                                <Col>
                                <span className="info-text">{t('2024-6-19')}</span>
                                </Col>
                            </Row>
                            {/* <div>
                                <img src={icon3} alt="Date" className="info-icon me-2" />
                                <span className="info-text">19 Jun 2024</span>
                            </div> */}
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-center'>
                        <div className="about-description-div">
                                <p className={i18n.language === 'ar' ? "section-description-ar about-description" : "section-description about-description"}>
                                {t('HomePage.AboutUs.text')}
                            </p>
                            <p className={i18n.language === 'ar' ? "section-description-ar about-description about-description-mobile" : "section-description about-description about-description-mobile"}>
                                <span className={i18n.language === 'ar' ? 'our-vision-span-ar' : 'our-vision-span'}>{t('HomePage.AboutUs.OurVision.title')}</span><br />
                                {t('HomePage.AboutUs.OurVision.text')}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </section>
    );
}

export default AboutUs;
