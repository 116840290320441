import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";

function HeroSlogen({ currentPage }) {
    const { i18n, t } = useTranslation();
    const [animationKey, setAnimationKey] = useState(0);
    const handleScrollClick = (e) => {
        e.preventDefault();
        let div;
        if (currentPage === 'home')
            div = document.getElementById('services');
        else
            div = document.getElementById('work');
        const y = div.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    useEffect(() => {
        setAnimationKey(prevKey => prevKey + 1); 
    }, [t('HomePage.HeroSection.slogen1'), t('HomePage.HeroSection.Slogen2')])
    
    return (
        <div
            className="text-white mt-5 teams-hero-slogen-div"
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '100' }}
        >
            <div
                className="text-center teams-hero-slogen"
                style={{
                    fontFamily: 'Segeo-UI-Light',
                    fontSize: '2.2em',
                    letterSpacing: '0',
                    textShadow: '2px 2px 2px rgba(0, 0, 0, 0.30)'
                }}
            >
                { currentPage === 'home' && (<div data-aos="zoom-in" data-aos-duration="1000">{t('Motion Visual Media')}</div>) }
                { currentPage === 'work' && (
                    <div>
                        {t('Motion')} <span style={{ borderBottom: '1px solid white'}}>{t('Visual')}</span> {t('Media')}
                    </div>
                )}
            </div>
            { currentPage === 'home' && (
                <div
                    // dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}
                    className="text-center teams-hero-slogen-desc"
                    style={{ fontSize: '2.3em', fontFamily: 'Segeo-UI-Regular', textShadow: '2px 2px 2px rgba(0, 0, 0, 0.30)' }}
                >
                    {/* {'"Your Vision, Our Craft"'} */}
                    <TypeAnimation
                        key={animationKey}
                        sequence={[
                        // Same substring at the start will only be typed out once, initially
                        `"${t("Your Vision, Our Craft")}"`,
                        1500,
                        '',
                        1500,
                        `"${t("Your Vision, Our Art")}"`,
                        1500
                        ]}
                        wrapper="span"
                        speed={10}
                        repeat={Infinity}
                    />
                </div>
            )}
            {/* <div className="d-flex justify-content-center mt-3 mb-3">
                <div style={{ width: '100px', height: '1px', borderBottom: '1px solid white' }}></div>
            </div> */}
            <div className="arrow-down d-flex justify-content-center">
                <a href="#our-teams-features" onClick={handleScrollClick}>
                    {/* <div
                        className="d-flex justify-content-center align-items-center teams-arrow-down"
                    >
                        <i className="bi bi-arrow-down-circle arrow-down-icon"></i>

                    </div> */}
                    <div className="arrow-container d-flex justify-content-center align-items-center teams-arrow-down">
                        <i className="bi bi-arrow-down arrow-icon"></i>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HeroSlogen;