import '../../assets/css/clients.css';
import useSWR from 'swr';
import { useState, useEffect } from 'react';

function OurClients() {
    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR(`https://mvm.art/api/our-client-list/`, fetcher)
    const [sliderImages, setSliderImages] = useState([
        "https://www.zarla.com/images/nike-logo-2400x2400-20220504.png?crop=1:1,smart&width=150&dpr=2",
        "https://www.zarla.com/images/apple-logo-2400x2400-20220512-1.png?crop=1:1,smart&width=150&dpr=2",
        "https://www.zarla.com/images/disney-logo-2400x2400-20220513-2.png?crop=1:1,smart&width=150&dpr=2",
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/4d/Loon_%28company%29_logo.svg/800px-Loon_%28company%29_logo.svg.png",
        "https://brandlogos.net/wp-content/uploads/2014/12/starbucks_coffee_company-logo_brandlogos.net_9jqys.png",
        "https://brandlogos.net/wp-content/uploads/2014/12/starbucks_coffee_company-logo_brandlogos.net_9jqys.png"
    ]);

    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                let tempSliderImages = []
                data.forEach((obj) => {
                    tempSliderImages.push(obj.image)
                })
                setSliderImages(tempSliderImages)
            }
        }
    }, [data, error, isLoading]);
    
    return (
        <>
            <div className="clients-slider-section">
            <div className="title-section text-center">
                <div className="line mb-4 mt-5"></div>
                <h2 className="section-title mb-2">Our Clients</h2>
                <div className='section-description d-flex justify-content-center'>
                    <p className="text-center services-section-description">
                        We are proud to partner with an incredible  clients across various industries. Together, we achieve great milestones and foster long-lasting relationships. Thank you for being a part of our journey.
                    </p>
                </div>
            </div>
            <div className="clients-slider mt-2 mb-5">
                <div className="clients-slider-items">
                    {sliderImages.map((clientLogo, index) => {
                        return (
                            <img src={clientLogo} key={index} alt='partner-logo' />
                        )
                    })}
                </div>
            </div>
            </div>
        </>
    );
}

export default OurClients;