import { Container, Row, Col } from "react-bootstrap";
import bg from '../../assets/images/get-in-touch-bg.png';
function GalleryFollowUs () {
    <Container fluid style={{ minHeight: '225px', backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <Row className="m-5">
            <Col>
                <h1>Hello World</h1>
                <p>Stay ahead with exclusive updates and news through out our social pages</p>
                <button></button>
            </Col>
        </Row>
    </Container>
}

export default GalleryFollowUs;