import React, { useState, useEffect } from 'react';
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Hero from "../layout/Hero";
import SplittingLine from '../layout/SplittingLine';
import GalleryFollowUs from "./GalleryFollowUs";
import GalleryIcons from "./GalleryIcons";

import { Container, Row, Col, Button } from "react-bootstrap";
import bg from '../../assets/images/get-in-touch-bg.png';
import RecentWork from '../homePage/RecentWork';
import VideoPlayer1 from './VideoPlayer';


import facebook from '../../assets/images/facebook-icon.png';
import twitter from '../../assets/images/x-icon.png';
import youtube from '../../assets/images/youtube-icon.png';
import instagram from '../../assets/images/instagram-icon.png';
import tiktok from '../../assets/images/tiktok-logo.png';
import followUsImage from '../../assets/images/get-in-touch-bg.jpg';
import addressIcon from '../../assets/images/address-icon.png';
import phoneIcon from '../../assets/images/phone-icon.png';
import emailIcon from '../../assets/images/email-icon.png';
import Loader from '../layout/Loader';
import ChatBot from '../layout/ChatBot';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useLoading } from "../../contexts/LoaderContext";

function GalleryPage() {
    const {i18n, t} = useTranslation();

    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR('https://mvm.art/api/work-page-details/', fetcher)
    const { setIsLoading } = useLoading()

    const [workPageData, setWorkPageData] = useState({
        follow_us: "",
    })

    useEffect(() => {
        setIsLoading(isLoading);
        if (data) {
            const firstObj = data[0]
            setWorkPageData(firstObj);
        }
    }, [data, error, isLoading]);

    return (
        <>
            <Header />
            {/* <VerticalHero /> */}
            <Hero currentPage={'work'} />
            <SplittingLine />
            <GalleryIcons />
            {/* Video Player */}
            <RecentWork currentPage={'work'} />
            {/* <Container fluid>
                <Container>
                    <Row>
                        <Col className='mt-5 mb-5' md={12}>
                            <div className="section-title text-center">SEE OUR RECENT WORK</div>
                            <div className='section-description text-center mt-3'>At MVM, we take price in our diverse portfolio of sucessful projects. Our work spans accross various industries, showcasing our ability to create tailored advertising and visual content that meets the unique needs of each client</div>
                        </Col>
                    </Row>
                    <RecentWork />
                </Container>
            </Container> */}

                        {/* Video Player */}
            <Container fluid style={{
                minHeight: '350px',
                backgroundImage: workPageData?.follow_us ? `url(${workPageData.follow_us})` : `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'white',
                overflow: 'hidden'
            }}>
                <Row>
                    <Col className='mb-5'>
                        <h1 className={i18n.language === 'ar' ? "section-title-ar" : "section-title"} data-aos="fade-down" data-aos-duration="1000">{t('WorkPage.Subscribe.title')}</h1>
                        <p className={` ${i18n.language === 'ar' ? 'section-description-ar' : 'section-description'} text-white`}>{t('WorkPage.Subscribe.slogen')}</p>
                        {/* <Button variant="light" className="send-button">FOLLOW US</Button> */}
                        <a className="btn-flip" data-aos="fade-up" data-aos-duration="1000">
                            <span className="front-text" dir={'rtl'} style={i18n.language === 'ar' ? { fontFamily: 'Hacen-Bold', fontSize: '1.1em' } : { fontFamily: 'Segeo-UI-Regular', fontSize: '1.1em' }}>{t('WorkPage.Subscribe.frontButton')}</span>
                            <span className="back-icons d-flex justify-content-center">
                                    <div className="contact-info-icon d-flex justify-content-center text-center icon">
                                        <a href="https://www.facebook.com/profile.php?id=61562733960359&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" ><img width={'10px'} src={facebook} alt="facebook" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center icon">
                                        <a href="https://x.com/mvmedia_art" target="_blank" rel="noopener noreferrer" ><img width={'17px'} src={twitter} alt="X" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center icon">
                                        <a href="https://www.instagram.com/mvm.art.company/?next=%2F" target="_blank"><img width={'20px'} src={instagram} alt="instagram" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center icon">
                                        <a href="https://www.youtube.com/@MVMArtCompany" target="_blank"><img width={'23px'} src={youtube} alt="youtube" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center icon">
                                        <a href="https://www.tiktok.com/@mvmedia_art" target="_blank"><img width={'18px'} src={tiktok} alt="tiktok" /></a>
                                    </div>
                            </span>
                        </a>
                        {/* <a href="#" class="btn-flip" data-back="Back" data-front="Front"></a> */}
                        {/* <a href="#" class="btn-flip" data-front="Follow Us">
                            <span class="front-text">Follow Us</span>
                            <span class="back-icons">
                                <i class="icon icon1"></i>
                                <i class="icon icon2"></i>
                                <i class="icon icon3"></i>
                            </span>
                        </a> */}
                    </Col>
                </Row>
            </Container>
            <GalleryFollowUs />
            <SplittingLine />
            <ChatBot />
            <Footer />
        </>
    )
}

export default GalleryPage;