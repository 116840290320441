import {  useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../../assets/css/hero.css';

// import required modules
// import { Autoplay, Pagination } from 'swiper';
import slide11 from '../../assets/images/slide11.jpg';
import slide22 from '../../assets/images/slide22.jpg';
import slide22_updated from '../../assets/images/08.jpg';
import slide44 from '../../assets/images/slide44.jpg';
import slide1 from '../../assets/images/slide1.png';
import slide2 from '../../assets/images/slide2.png';

import slide3 from '../../assets/images/slide3.png';
import slide4 from '../../assets/images/slide4.png';

import arrow_down from '../../assets/images/arrow-down.png';

import { Autoplay, Pagination } from 'swiper/modules';
import useSWR from 'swr';
import HeroSlogen from './HeroSlogen';


export default function Hero({ currentPage }) {
    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR(`https://mvm.art/api/${currentPage}-hero-slides/`, fetcher)
    const [sliderImages, setSliderImages] = useState([slide11, slide22_updated, slide2, slide44]);

    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                let tempSliderImages = []
                data.forEach((obj) => {
                    tempSliderImages.push(obj.image)
                })
                setSliderImages(tempSliderImages)
            }
        }
    }, [data, error, isLoading]);

    return (
        <>
            <section id="hero">
            <Swiper
                direction={ currentPage === 'work' ? 'vertical' : 'horizontal'}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                loop={true}
                pagination={{
                    clickable: true
                }}
                modules={[Pagination, Autoplay]}
                className={`mySwiper hero-swiper teams-swiper ${ currentPage === 'work' ? 'workSwiper' : ''}`}
                style={{ position: 'relative' }}
            >
                {sliderImages.map((forTeamsSliderImage, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <img src={forTeamsSliderImage} alt={'hi'} />
                        </SwiperSlide>
                    );
                })}
                <HeroSlogen currentPage={currentPage} />
            </Swiper>
            </section>
        </>
    );
}
