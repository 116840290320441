import { useEffect, useState, useTransition } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import contactUsImageWideScreens from '../../assets/images/contact-us-bg.jpg';
import contactUsImageMobile from '../../assets/images/contact-us-mobile.jpg';
import '../../assets/css/contact.css';

const ContactUs = ({contactUsWebImage, contactUsMobileImage}) => {
    const { i18n, t } = useTranslation();
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [contactBg, setContactBg] = useState(contactUsImageWideScreens)
    const [contactUsData, setContactUsData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleContactUsDataChange = (event) => {
        setContactUsData({
            ...contactUsData,
            [event.target.name]: event.target.value
        });
    };

    const submitContactUs = (event) => {
        event.preventDefault(); // Prevent page reload
        const contactUsFormData = new FormData();
        contactUsFormData.append('name', contactUsData.name);
        contactUsFormData.append('email', contactUsData.email);
        contactUsFormData.append('subject', contactUsData.subject);
        contactUsFormData.append('message', contactUsData.message);
        setIsSending(true);
        try {
            axios
                .post('https://mvm.art/api/contact-us-email/', contactUsFormData)
                .then((response) => {
                    setContactUsData({
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    });
                    // notify();
                    setIsSending(false);
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 2000); // Show success icon for 2 seconds
                })
                .catch((error) => {
                    if (error) {
                        // errorNotify();
                        setIsSending(false);
                        setIsSuccess(true);
                        setTimeout(() => {
                            setIsSuccess(false);
                        }, 2000); // Show success icon for 2 seconds
                    }
                });
        } catch (error) {
            if (error) {
                // errorNotify();
                setIsSending(false);
            }
        }
    };

    useEffect(() => {
        if (window.innerWidth < 768 && !contactUsMobileImage)
            setContactBg(contactUsImageMobile);
        else if (window.innerWidth < 768 && contactUsMobileImage)
            setContactBg(contactUsMobileImage);
        else {
            if (contactUsWebImage)
                setContactBg(contactUsWebImage);
            else
                setContactBg(contactUsImageWideScreens);
        }
    }, [])

    return (
        <section id="contact" style={{ overflowX: 'hidden' }}>
            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} className="contact-form-container" style={{ backgroundImage: `url(${contactBg})` }}>
                <Container fluid className="contact-form-content">
                    <Row className="justify-content-center mb-4">
                        <Col xs={12} className="text-center title-section">
                            <div className="line mb-4" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)'}}></div>
                            <h1 className={i18n.language === 'ar' ? "section-title-ar" : "section-title"} style={{ color: 'rgba(255, 255, 255, 0.9)'}}>{t('HomePage.ContactUs.title')}</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <Form onSubmit={submitContactUs}>
                                <Row>
                                    <Col md={5} className="mb-3 order-md-1 order-3">
                                        <Form.Group controlId="formFullName">
                                            <Form.Label className={i18n.language === 'ar' ? "form-label form-label-ar" : "form-label"}>{t('HomePage.ContactUs.form.fullName.label')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('HomePage.ContactUs.form.fullName.placeholder')}
                                                className={i18n.language === 'ar' ? "form-input form-input-ar" : "form-input"}
                                                name="name"
                                                value={contactUsData.name}
                                                onChange={handleContactUsDataChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className="order-md-2 order-2"></Col>
                                    <Col md={5} className={`mb-3 ${i18n.language === 'ar' ? 'form-description-ar' : 'form-description'} order-md-3 order-1`}>
                                        <p>{t('HomePage.ContactUs.slogen')}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5} className="mb-3">
                                        <Form.Group controlId="formEmail">
                                            <Form.Label className={i18n.language === 'ar' ? "form-label form-label-ar" : "form-label"} >{t('HomePage.ContactUs.form.email.label')}</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={t('HomePage.ContactUs.form.email.placeholder')}
                                                className={i18n.language === 'ar' ? "form-input form-input-ar" : "form-input"}
                                                name="email"
                                                value={contactUsData.email}
                                                onChange={handleContactUsDataChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}></Col>
                                    <Col md={5} className="mb-3">
                                        <Form.Group controlId="formSubject">
                                            <Form.Label className={i18n.language === 'ar' ? "form-label form-label-ar" : "form-label"}>{t('HomePage.ContactUs.form.subject.label')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('HomePage.ContactUs.form.subject.placeholder')}
                                                className={i18n.language === 'ar' ? "form-input form-input-ar" : "form-input"}
                                                name="subject"
                                                value={contactUsData.subject}
                                                onChange={handleContactUsDataChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <Form.Group controlId="formMessage">
                                            <Form.Label className={i18n.language === 'ar' ? "form-label form-label-ar" : "form-label"}>{t('HomePage.ContactUs.form.message.label')}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                placeholder={t('HomePage.ContactUs.form.message.placeholder')}
                                                className={i18n.language === 'ar' ? "form-input form-input-ar message-form-input" : "form-input message-form-input"}
                                                name="message"
                                                value={contactUsData.message}
                                                onChange={handleContactUsDataChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mt-4">
                                    <Col xs="auto">
                                        <Button variant="light" type="submit" className={i18n.language === 'ar' ? 'send-button-ar' : 'send-button'} data-aos="zoom-in" data-aos-duration="1000">
                                            {isSending ? (
                                                <div className="lds-ring">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            ) : isSuccess ? (
                                                <div className="success-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15.25A7.25 7.25 0 1 1 15.25 8 7.259 7.259 0 0 1 8 15.25zM8 1.75A6.25 6.25 0 1 0 14.25 8 6.258 6.258 0 0 0 8 1.75zm3.553 6.146a.5.5 0 0 0-.707 0L8 8.793 7.154 7.947a.5.5 0 0 0-.707.707l1 1a.5.5 0 0 0 .707 0l2-2a.5.5 0 0 0 0-.707z"/>
                                                    </svg>
                                                </div>
                                            ) : (
                                                <div>{t('HomePage.ContactUs.form.send.label')}</div>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default ContactUs;
