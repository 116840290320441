import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Fade } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/services.css'; // Create this file for custom styles

// Import your icons here
import icon1 from '../../assets/images/advertisements-icon.png';
import icon2 from '../../assets/images/production-icon.png';
import icon3 from '../../assets/images/post-production-icon.png';
import icon4 from '../../assets/images/visual-content-icon.png';

// import { ReactComponent as MySvg } from '../../assets/images/svg1.svg';
import AdvertisementIcon from '../AdvertisementIconSvg';
import ProductionIconSvg from '../ProductionIconSvg';
import PostProductionIcon from '../PostProductionIconSvg';
import VisualContentIcon from '../VisualContentIconSvg';
//
import { services } from '../layout/serviceData';
import ServiceDetails from './ServiceDetails';
import { useTranslation } from 'react-i18next';

function Services({ advertisementImages, productionImages, postProductionImages, visualContentImages}) {
    const { i18n, t } = useTranslation();
    const [selectedServiceIndex, setSeletctedServiceIndex] = useState(0);
    const [selectedService, setSelectedService] = useState(services[0]);

    // State to control the fade effect
    const [isVisible, setIsVisible] = useState(false);
    const fadeRef = useRef(null);

    const handleServiceClick = (serviceIndex) => {
        setSeletctedServiceIndex(serviceIndex)
        const currentService = services[serviceIndex]
        if (advertisementImages[0]) {
            if (currentService.title === "Advertisement")
                currentService.images = advertisementImages;
            else if (currentService.title === "Production")
                currentService.images = productionImages;
            else if (currentService.title === "Post Production")
                currentService.images = postProductionImages;
            else
                currentService.images = visualContentImages;
        }
        setSelectedService(currentService);
        let div;
        div = document.getElementById('serviceDetails');
        const y = div.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    useEffect(() => {
        const pos = document.documentElement;
        const handleMouseMove = (e) => {
            pos.style.setProperty('--x', e.clientX + 'px');
            pos.style.setProperty('--y', e.clientY + 'px');
            const p = e.currentTarget.querySelector('.services-section-description');

        };
        pos.addEventListener('mousemove', handleMouseMove);

        return () => {
            pos.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log("this is entry: ", entry)
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.9 } // Adjust this value to control when the fade effect triggers
        );

        const element = fadeRef.current;
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    useEffect(() => {
        if (productionImages[0]) {
            const firstProductionObj = services[0];
            firstProductionObj.images = productionImages;
            setSelectedService(firstProductionObj);
        }
    }, [productionImages])

    return (
        <>
        <section id="services" style={{ overflow: "hidden" }}>
        <Container className="services-section text-center py-5" fluid>
            <Container>
                <div className="title-section">
                    <div className="line mb-4"></div>
                    <div style={{ position: 'relative' }}>
                        <h2 className={` ${i18n.language === 'ar' ? 'section-title-ar' : 'section-title' } mb-2`} style={{ color: "#a1a1a1", cursor: 'default' }}>{t('HomePage.OurServices.title')}</h2>
                        {/* <div className='spot-light'></div> */}
                    </div>
                    <div className={`${i18n.language === 'ar' ? 'section-description-ar' : 'section-description'} d-flex justify-content-center`}>
                        <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                            <Fade in={isVisible}>
                                <p dir={i18n.language == 'ar' ? 'rtl' : 'ltr'} ref={fadeRef} className="text-center services-section-description" style={{ cursor: 'default' }}>
                                    {t('HomePage.OurServices.slogen')}
                                </p>
                            </Fade>
                            <div className='spot-white-light'></div>
                        </div>
                    </div>
                </div>
                <Row className="services-row mt-3 ms-2 me-2" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <Col md={3} xs={6} className="service-col mb-4" onClick={() => handleServiceClick(1)} onFocus={() => handleServiceClick(1)}>
                        <div data-aos="zoom-in" data-aos-duration="1000">
                            <div className="service-icon d-flex justify-content-center mb-3">
                                {/* <img src={icon1} alt="Advertisements" /> */}
                                <AdvertisementIcon divClassName={`services-svg-icon ${selectedServiceIndex === 1 ? 'service-selected-active': ''}`} />
                                {/* {icon11} */}
                                {/* <MySvg style={{ fill: 'blue', color: 'blue' }} /> */}
                            </div>
                            <h4 className={`${i18n.language === 'ar' ? 'service-title-ar' : 'service-title'}`}>{t('HomePage.OurServices.service1.title')}</h4>
                            <div className='service-description d-flex justify-content-center'>
                                <p className={`${i18n.language === 'ar' ? 'service-description-p-ar' : 'service-description-p'}`}>{t('HomePage.OurServices.service1.slogen')}</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className={`service-line ${selectedServiceIndex === 1 ? 'service-line-active': ''}`}></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={6} className="service-col mb-4" onClick={() => handleServiceClick(0)}>
                        <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                            <div className="service-icon d-flex justify-content-center mb-3">
                                {/* <img width={'30px'} src={icon2} alt="Production" /> */}
                                <ProductionIconSvg divClassName={`services-svg-icon ${selectedServiceIndex === 0 ? 'service-selected-active': ''}`} />
                            </div>
                            <h4 className={`${i18n.language === 'ar' ? 'service-title-ar' : 'service-title'}`}>{t('HomePage.OurServices.service2.title')}</h4>
                            <div className="service-description d-flex justify-content-center">
                                <p className={`${i18n.language === 'ar' ? 'service-description-p-ar' : 'service-description-p'}`}>{t('HomePage.OurServices.service2.slogen')}</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className={`service-line ${selectedServiceIndex === 0 ? 'service-line-active': ''}`}></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={6} className="service-col mb-md-4" onClick={() => handleServiceClick(2)}>
                        <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000">
                            <div className="service-icon d-flex justify-content-center">
                                {/* <img width={'30px'} src={icon3} alt="Post-Production" /> */}
                                <PostProductionIcon divClassName={`services-svg-icon ${selectedServiceIndex === 2 ? 'service-selected-active': ''}`} />
                            </div>
                            <h4 className={`${i18n.language === 'ar' ? 'service-title-ar' : 'service-title'}`}>{t('HomePage.OurServices.service3.title')}</h4>
                            <div className="service-description d-flex justify-content-center">
                                <p className={`${i18n.language === 'ar' ? 'service-description-p-ar' : 'service-description-p'}`} dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>{t('HomePage.OurServices.service3.slogen')}</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className={`service-line ${selectedServiceIndex === 2 ? 'service-line-active': ''}`}></div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} xs={6} className="service-col mb-md-4" onClick={() => handleServiceClick(3)}>
                        <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1500">
                            <div className="service-icon d-flex justify-content-center mb-3">
                                {/* <img width={'30px'} src={icon4} alt="Visual Content" /> */}
                                <VisualContentIcon divClassName={`services-svg-icon ${selectedServiceIndex === 3 ? 'service-selected-active': ''}`} />
                            </div>
                            <h4 className={`${i18n.language === 'ar' ? 'service-title-ar' : 'service-title'}`}>{t('HomePage.OurServices.service4.title')}</h4>
                            <div className="service-description d-flex justify-content-center">
                                <p className={`${i18n.language === 'ar' ? 'service-description-p-ar' : 'service-description-p'}`}>{t('HomePage.OurServices.service4.slogen')}</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className={`service-line ${selectedServiceIndex === 3 ? 'service-line-active': ''}`}></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        </section>
        <ServiceDetails service={selectedService} />
        </>
    );
}

export default Services;
