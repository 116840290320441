import React, { useEffect } from 'react';
import '../../assets/css/chatbot.css';
// import chatImg from '../../assets/images/image-icon.webp';

import chatImg from '../../assets/images/image-icon2.png';

import { useLoading } from '../../contexts/LoaderContext';

function ChatBot() {
    const { isLoading } = useLoading();
    // useEffect(() => {
    //     if (!isLoading) {
    //         setTimeout(function () {
    //             document
    //                 .querySelector('df-messenger')
    //                 .shadowRoot.querySelector('df-messenger-chat')
    //                 .shadowRoot.querySelector('df-messenger-user-input')
    //                 .shadowRoot.querySelector('.input-box-wrapper > input').placeholder = 'Enter Your Message Here...';
    //         }, 1000);
    //         const inputElement = document.querySelector('.input-box-wrapper input');
    //         if (inputElement) {
    //             inputElement.placeholder = 'Enter Your Message Here...';
    //         }
    //     }
    // }, [isLoading]);


    useEffect(() => {
        if (!isLoading) {
            const handleClickOutsideChat = (event) => {
                const dfMessenger = document.querySelector('df-messenger');
                const clickedElement = event.target;
    
                // Check if the clicked element is not within the df-messenger component
                if (!dfMessenger.contains(clickedElement)) {
                    // Close the chat by simulating a click on the close button
                    const closeButton = dfMessenger.shadowRoot.querySelector('#closeIcon');

                    const widgetIcon = dfMessenger.shadowRoot.querySelector('#widgetIcon img');
                    if (widgetIcon.classList.contains('hidden')) {
                        closeButton.click();
                    } else if (!widgetIcon.classList.contains('hideen')) {
                    }
    
                }
            };
    
            window.addEventListener('click', handleClickOutsideChat);
    
            return () => {
                window.removeEventListener('click', handleClickOutsideChat);
            };
        }
    }, [isLoading]);

    useEffect(() => {
        if (!isLoading) {
            let isCleanup = false;
            const applyTransparentBackground = () => {
                try {
                    const dfMessenger = document.querySelector('df-messenger');
                    const dfMessengerChat = dfMessenger.shadowRoot.querySelector('df-messenger-chat');
                    const dfChatWrapper = dfMessengerChat.shadowRoot.querySelector('.chat-wrapper');
                    const dfChatIcon = dfMessenger.shadowRoot.querySelector('.df-chat-icon');
                    const widgetIcon1 = dfMessenger.shadowRoot.querySelector('#widgetIcon');
                    if (window.innerWidth > 786)
                        widgetIcon1.style.marginRight = "35px";
                    else {
                        widgetIcon1.style.width = "46px";
                        widgetIcon1.style.height = "46px";
                        dfChatIcon.style.height = "27px";
                        dfChatIcon.style.width = "27px";

                    }
                    // if (dfChatIcon) {
                    //     if (dfChatIcon.tagName.toLowerCase() === 'div') {
                    //         const newImg = document.createElement('img');
                    //         newImg.src = "https://cdn1.iconfinder.com/data/icons/heroicons-ui/24/chat-1024.png";
                    //         newImg.setAttribute('class', 'df-chat-icon');
                    //         dfChatIcon.parentNode.replaceChild(newImg, dfChatIcon);
                    //     }
                    // }
                    dfChatIcon.style.opacity = "0.5";
                    dfChatWrapper.style.backgroundColor = 'transparent';
                    dfChatWrapper.style.border = '1px solid white';
                    // dfChatIcon.src = "https://cdn1.iconfinder.com/data/icons/heroicons-ui/24/chat-1024.png"
                    if (window.innerWidth <= 678) {
                        dfChatWrapper.style.height = '66%';
                    } else {
                        dfChatWrapper.style.height = "66%";
                    }
                } catch (error) {
                    // Handle the error (e.g., log it or perform any necessary actions)
                    console.error('An error occurred while applying the transparent background: or replacing div with image', error);
                } finally {
                    if (!isCleanup) {
                        // Continue calling the effect only if it hasn't been cleaned up
                        setTimeout(applyTransparentBackground, 50); // Adjust the delay as needed
                    }
                }
            };
    
            // Call the effect initially
            applyTransparentBackground();
    
            return () => {
                // Set the cleanup flag to true to prevent further calls to the effect
                isCleanup = true;
            };
        }
        
        
    }, [isLoading]);

    return (
        // <df-messenger
        //     intent="WELCOME"
        //     chat-title="Tiktag Bot"
        //     agent-id="0e4d1e6e-d4cc-41f7-ae61-b2bd96d04bd7"
        //     language-code="en"
        //     chat-icon="https://tiktag.co/media/qr-codes/36T_logo_2.png"
        //     wait-open="true"
        // ></df-messenger>
        <df-messenger
        intent="WELCOME"
        chat-title="MVM"
        agent-id="1ba959cb-10b7-4aa0-8ba1-5d7b96aca6fd"
        language-code="en"
        chat-icon={chatImg}
        wait-open="true"
        ></df-messenger>
    );
}

export default ChatBot;
