import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination } from 'swiper/modules';

import '../../assets/css/recent_work.css';

// import vidImg1 from '../../assets/images/video-img1.jpg';
import vidImg1 from '../../assets/images/08.jpg';
import vidImg2 from '../../assets/images/video-img2.jpg';
import vidImg3 from '../../assets/images/video-img3.jpg';

import video1 from '../../assets/videos/mvm-lvideo.mp4';
import video2 from '../../assets/videos/mvm-lvideo.mp4';
import video3 from '../../assets/videos/mvm-lvideo.mp4';

import { useNavigate, useLocation } from 'react-router-dom';
import { useScrollContext } from '../ScrollProvider';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../contexts/LoaderContext';
import { useSelectingVideo } from '../../contexts/SelectedVideoContext';
import useSWR from 'swr';

const RecentWork = ({ currentPage }) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const videoRef = useRef(null);
    const { setScrollTo } = useScrollContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeIndex, setActiveIndex] = useState(1); // Default to the second slide
    const { setIsLoading } = useLoading();
    const { selectedVideo, setSelectedVideo } = useSelectingVideo();
    const [recentWorkVideos, setRecentWorkVideos] = useState([])
    const [mainVideo, setMainVideo] = useState(video1)

    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data, error, isLoading } = useSWR('https://mvm.art/api/work-gallery-videos/', fetcher)

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    };

    const togglePlayPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    // const handleVideoClick = () => {
    //     navigate('/work#work');
    // }
    const handleVideoClick = () => {
        if (location.pathname === '/work') {
            const section = document.getElementById('work');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate('/work')
            setScrollTo('work');
            setIsLoading('true');
        }
    }

    useEffect(() => {
        setIsLoading(isLoading);
        if (data) {
            if (!selectedVideo)
                setSelectedVideo(data[0]?.video)
            setRecentWorkVideos(data);
        } else 
            setSelectedVideo(video1)
    }, [data, error, isLoading]);


    return (
        <section id="work">
            <Container fluid className="text-center py-5 recent-work-section">
                <Container>
                    <div className="title-section">
                        { (currentPage === 'work' || currentPage === "home") && (<div className="line mb-4"></div>)}
                        <h2 className={i18n.language === 'ar' ? "section-title-ar mb-2" : "section-title mb-2"}>{t('HomePage.RecentWork.title')}</h2>
                        <div className='d-flex justify-content-center'>
                            <div style={{ width: '90%'}}>
                                <p className={i18n.language === 'ar' ? "section-description-ar" : "section-description"} style={{ textAlign: 'justify', textAlignLast: 'center' }} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                    {t('HomePage.RecentWork.slogen')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
                { currentPage === 'work' && (
                <Container className="mb-5 mt-4">
                    <Row>
                        <Col md={12}>
                            <div className="gallery-video-container">
                                <video ref={videoRef} src={selectedVideo} controls controlsList='nodownload' />
                                <i
                                    className={`bi ${isPlaying ? 'bi-pause-circle-fill' : 'bi-play-circle-fill'} play-pause-icon`}
                                    onClick={togglePlayPause}
                                ></i>
                            </div>
                        </Col>
                    </Row>
                </Container>
                )}
                <Swiper
                    autoplay
                    spaceBetween={10}
                    slidesPerView={3}
                    centeredSlides={true}
                    pagination={{
                        clickable: true
                    }}
                    modules={[Pagination, Autoplay]}
                    loop={true}
                    onSlideChange={handleSlideChange}
                    className="recent-work-slider"
                    breakpoints={{
                        0: {
                            slidesPerView: 1.5, // This will show a little bit of the next slide
                            spaceBetween: 10
                        },
                        768: {
                            slidesPerView: 3
                        }
                    }}
                >
                    {recentWorkVideos.length > 0 ? (
                        recentWorkVideos.map((vid, index) => (
                            <SwiperSlide
                                key={index}
                                className={`recent-work-slide ${activeIndex === index ? 'selected' : ''}`}
                                style={{ cursor: 'pointer' }}
                                onClick={handleVideoClick}
                            >
                                <div className="video-thumbnail">
                                    <video src={vid.video} onClick={() => setSelectedVideo(vid.video)}></video>
                                </div>
                                
                            </SwiperSlide>
                        ))
                    ): (
                        [vidImg1, vidImg2, vidImg3, vidImg1, vidImg2, vidImg3].map((vidImg, index) => (
                            <SwiperSlide
                                key={index}
                                className={`recent-work-slide ${activeIndex === index ? 'selected' : ''}`}
                                style={{ cursor: 'pointer' }}
                                onClick={handleVideoClick}
                            >
                                <div className="video-thumbnail">
                                    <video preload='none' poster={vidImg} src={video1} onClick={() => console.log("Clicked on the video!!")}></video>
                                </div>
                            </SwiperSlide>
                        ))
                    )}
                    {/* {
                        [vidImg1, vidImg2, vidImg3, vidImg1, vidImg2, vidImg3].map((vidImg, index) => (
                            <SwiperSlide
                                key={index}
                                className={`recent-work-slide ${activeIndex === index ? 'selected' : ''}`}
                                style={{ cursor: 'pointer' }}
                                onClick={handleVideoClick}
                            >
                                <div className="video-thumbnail">
                                    <video preload='none' poster={vidImg} src={video1} onClick={() => console.log("Clicked on the video!!")}></video>
                                </div>
                            </SwiperSlide>
                        ))
                    } */}
                </Swiper>

            </Container>
        </section>
    );
};

export default RecentWork;
