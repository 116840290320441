import { useState, useEffect } from 'react';
import { Fade } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../../assets/css/footer.css';
import logo3 from '../../assets/images/logo3.png';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { i18n, t } = useTranslation();
    const [showFooterElements, setShowFooterElements] = useState(false);

    const handleFooterButton = () => {
        setShowFooterElements(!showFooterElements);
    };

    useEffect(() => {
        function handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollBottom = scrollTop + windowHeight;

            if (scrollBottom >= documentHeight) {
                // Call your function here
                setShowFooterElements(true);
            } else {
                setShowFooterElements(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="container-fluid" style={{ background: '#1b1b1b', minHeight: '100px' }}>
            <div className="container">
                <div className="d-flex align-items-center" style={{ minHeight: '100px' }}>
                    <Link
                        to={'/#about'}
                        className={`col-6 col-md-2 text-end text-decoration-none ${i18n.language === 'ar' ? 'footer-links-ar' : 'footer-links'} not-on-mobile-footer-links`}
                        onClick={() => scrollToSection('about')}
                        style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}
                    >
                        <Fade in={showFooterElements}>
                            <div>{t('footer.about')}</div>
                        </Fade>
                    </Link>
                    <Link
                        to={'/#work'}
                        className={`col-6 col-md-2 text-end text-decoration-none ${i18n.language === 'ar' ? 'footer-links-ar' : 'footer-links'} not-on-mobile-footer-links`}
                        onClick={() => scrollToSection('work')}
                        style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}
                    >
                        <Fade in={showFooterElements}>
                            <div>{t('footer.work')}</div>
                        </Fade>
                    </Link>
                    <Link
                        to={'/#hero'}
                        onClick={() => window.scrollTo(0, 0)}
                        className="col-6 col-md-4 text-center text-decoration-none logo-footer"
                        onMouseOver={handleFooterButton}
                        style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}
                    >
                        <img id="footerLogo" src={logo3} alt={'logo'} />
                    </Link>
                    <Link
                        to={'/#services'}
                        className={`col-6 col-md-2 text-start text-decoration-none ${i18n.language === 'ar' ? 'footer-links-ar' : 'footer-links'} not-on-mobile-footer-links`}
                        onClick={() => scrollToSection('services')}
                        style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}
                    >
                        <Fade in={showFooterElements}>
                            <div>{t('footer.services')}</div>
                        </Fade>
                    </Link>
                    <Link
                        to={'/#contact'}
                        className={`col-6 col-md-2 text-start text-decoration-none ${i18n.language === 'ar' ? 'footer-links-ar' : 'footer-links'} not-on-mobile-footer-links`}
                        onClick={() => scrollToSection('contact')}
                        style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}
                    >
                        <Fade in={showFooterElements}>
                            <div>{t('footer.contact')}</div>
                        </Fade>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;
