import { Container, Row, Col } from "react-bootstrap";

import productionIcon from '../../assets/images/production-icon.png';
import postProductionIcon from '../../assets/images/post-production-icon.png';
import visualContentIcon from '../../assets/images/visual-content-icon.png';

import ProductionIconSvg from '../ProductionIconSvg';
import PostProductionIcon from '../PostProductionIconSvg';
import VisualContentIcon from '../VisualContentIconSvg';

import { useTranslation } from "react-i18next";



function GalleryIcons () {
    const {i18n, t} = useTranslation();

    const columnsData = [
        {id: 1, image: <ProductionIconSvg divClassName={'services-svg-icon'} />, text: t('WorkPage.GalleryIcons.text1')},
        {id: 2, image: <PostProductionIcon divClassName={'services-svg-icon'} />, text: t('WorkPage.GalleryIcons.text2')},
        {id: 3, image: <VisualContentIcon divClassName={'services-svg-icon'} />, text: t('WorkPage.GalleryIcons.text3')}
    ]

    return (
        <Container fluid style={{ backgroundColor: '#1c1c1c', overflow: 'hidden' }}>
            <Container>
                <Row className="d-flex justify-content-center">
                    {columnsData.map((columnData) => (
                        <Col key={columnData.id} className="mt-5 mb-4 d-fservice-titlelex justify-content-center p-0">
                            <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay={(columnData.id - 1) * 500}>
                                <div className="d-flex justify-content-center">
                                    {columnData.image}
                                </div>
                                <p className={`mt-4 ${i18n.language === 'ar' ? 'service-title-ar' : 'service-title'} work-page-service-title`} style={{textAlign: 'center'}}>{columnData.text}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    )
}

export default GalleryIcons;