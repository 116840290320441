import React, { useState } from 'react';

const PostProductionIcon = ({ divClassName }) => {
  const [svgColor1, setSvgColor1] = useState('#464646'); // Initial color

  const handleMouseEnter1 = () => {
    setSvgColor1('#e5493a'); // Change color on hover (example: red)
  };

  const handleMouseLeave1 = () => {
    setSvgColor1('#464646'); // Reset color on mouse leave
  };

  return (
    <div className={divClassName}>
        <svg 
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 229.6 230.28"
        style={{ enableBackground: 'new 0 0 229.6 230.28', width: '99%', height: '99%' }}
        xmlSpace="preserve"
        // onMouseEnter={handleMouseEnter1}
        // onMouseLeave={handleMouseLeave1}
        >
        <style type="text/css">
          {`
            .st0{fill:${svgColor1};transition:all 0.3s;}
          `}
        </style>
        <g>
            <path className="st0" d="M9.7,157.3c-4.8-12.6-7.3-26.2-7.3-40.3c0-31.7,12.9-60.5,33.8-81.3C57.1,15,85.9,2.1,117.8,2.1
        c13.7,0,27.2,2.4,39.8,7c12.4,4.5,24,11.1,34.3,19.8c1.6,1.4,2,3.1,0.7,4.9c-1,1.4-3,2-4.6,0.9c-9.8-8.3-20.9-14.7-32.7-19
        c-11.9-4.3-24.5-6.6-37.4-6.6c-29.9,0-57.1,12.1-76.7,31.6C21.5,60.2,9.4,87.2,9.4,117c0,13.4,2.4,26.1,6.9,37.9
        c4.6,12.2,11.4,23.4,19.9,33.1c1.3,1.2,0.7,3.6-0.6,4.7c-1.3,1.1-3.5,1.2-4.7-0.1C21.7,182.2,14.5,170.3,9.7,157.3L9.7,157.3z"/>
      <path className="st0" d="M204.2,41.2c9,10.3,16.2,22.2,21.1,35.2c4.8,12.6,7.3,26.2,7.3,40.4c0,31.7-12.9,60.4-33.8,81.2
        c-20.9,20.8-49.7,33.6-81.6,33.6c-13.7,0-27.2-2.4-39.8-7c-12.6-4.6-24.4-11.4-34.9-20.2v0c-0.7-0.6-1.2-1.5-1.2-2.6
        c0-0.9,0.4-1.8,1-2.4c1.3-1.3,3.4-1.3,4.6-0.3l0,0c9.8,8.3,20.9,14.7,32.8,19c11.8,4.3,24.5,6.6,37.4,6.6
        c29.9,0,57.1-12.1,76.7-31.6c19.6-19.5,31.7-46.5,31.7-76.3c0-13.4-2.4-26.1-6.9-37.9c-4.6-12.1-11.3-23.3-19.9-33.1
        c-1.3-1.4-0.7-3.9,0.9-5C201.3,39.9,202.9,40,204.2,41.2L204.2,41.2z"/>
      <path className="st0" d="M132.5,111.2l-15,8.6l0,0L98.2,131V86.4l38.7,22.3L132.5,111.2z M114.6,114.8L114.6,114.8l10.6-6.1L104,96.5
        v24.5L114.6,114.8z"/>
      <path className="st0" d="M171,186.8c-35.7,0-71.3,0-107,0h0h0h-1.5c-0.6,0-2.5-1.4-2.5-3.6c0.1-2.7,2.2-3.7,2.5-3.7H64h32.2
        c0-0.1,0-0.2,0-0.3l1.8-17.2H64c-3.3,0-6.3-1.4-8.5-3.5c-2.2-2.2-3.5-5.2-3.5-8.5V68.4c0-3.3,1.3-6.3,3.5-8.5
        c2.2-2.2,5.2-3.5,8.5-3.5h107c3.3,0,6.3,1.3,8.5,3.5s3.5,5.2,3.5,8.5v81.6c0,3.3-1.3,6.3-3.5,8.5c-2.2,2.2-5.2,3.5-8.5,3.5h-34.3
        l3.4,16.8l0,0c0,0.3,0.1,0.5,0.1,0.7H171c0.3,0,2.4,1,2.5,3.7C173.5,185.4,171.6,186.8,171,186.8L171,186.8z M104.8,154.8h24.9H171
        c1.3,0,2.5-0.5,3.3-1.4c0.9-0.9,1.4-2,1.4-3.3V68.4c0-1.3-0.5-2.5-1.4-3.3c-0.9-0.9-2-1.4-3.3-1.4H64c-1.3,0-2.5,0.5-3.3,1.4
        c-0.9,0.9-1.4,2-1.4,3.3v81.6c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2,1.4,3.3,1.4H104.8z M129.3,162.1h-24l-1.8,17.1h29.3l-3.4-16.8
        C129.3,162.2,129.3,162.1,129.3,162.1L129.3,162.1z"/>
        </g>
        </svg>
    </div>
  );
};

export default PostProductionIcon;