import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import vid1 from '../../assets/videos/mvm-lvideo.mp4';
import HeroSlogen from './HeroSlogen';

function VideoHeroSection({ webVideo, mobileVideo}) {
    const currentWidth = window.innerWidth;
    const [currentVideo, setCurrentVideo] = useState(vid1);

    useEffect(() => {
        if (currentWidth < 768) {
            setCurrentVideo(mobileVideo);
        } else {
            setCurrentVideo(webVideo);
        }
    }, [webVideo, mobileVideo]);

    return (
        <div>
            <Container id="video-heor-section" fluid style={{ height: '100vh', background: 'black' }}>
                <Row>
                    <Col style={{ margin: '0px', padding: '0px', position: 'relative', width: '100vw', height: '100vh' }}>
                        <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            id="myVideo"
                            key={currentVideo}
                            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', objectFit: 'cover' }}
                        >
                            <source src={currentVideo} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                        <HeroSlogen currentPage={'home'} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default VideoHeroSection;
