import production1 from '../../assets/images/production1.png';
import production2 from '../../assets/images/production2.png';
import production3 from '../../assets/images/production3.png';
import production4 from '../../assets/images/production4.png';
import production33 from '../../assets/images/production33.jpg';
import production44 from '../../assets/images/02.svg';
/////////
import advertisement1 from '../../assets/images/advertisement1.png';
import advertisement2 from '../../assets/images/advertisement2.png';
import advertisement3 from '../../assets/images/advertisements3.png';
import advertisement4 from '../../assets/images/advertisements4.png';
import advertisement33 from '../../assets/images/advertisement33.jpg';
import advertisement44 from '../../assets/images/04.svg';
////////////
import postProduction1 from '../../assets/images/post-production1.png';
import postProduction2 from '../../assets/images/post-production2.png';
import postProduction3 from '../../assets/images/post-production3.png';
import postProduction4 from '../../assets/images/post-production4.png';
import postProduction33 from '../../assets/images/post-production33.jpg';
import postProduction44 from '../../assets/images/04.svg';
////////////
import visualContent1 from '../../assets/images/visual-content1.png';
import visualContent2 from '../../assets/images/visual-content2.png';
import visualContent3 from '../../assets/images/visual-content3.png';
import visualContent4 from '../../assets/images/visual-content4.png';
import visualContent33 from '../../assets/images/visual-content33.jpg';
import visualContent44 from '../../assets/images/04.svg';
// export const services = [
//     {
//         id: 1,
//         title: 'Production',
//         sideText: 'PRODUCTION',
//         bottomText: 'DESIGN & PRODUCTION',
//         subtitle1: 'Video Production:',
//         description1: 'Filming and production commercial and advertising videos',
//         subtitle2: 'Videography:',
//         description2: 'Shooting products & projects for use in advertisement and marketing',
//         images: [production1, production2, production3, production4]
//     },
//     {
//         id: 2,
//         title: 'Advertisement',
//         sideText: 'ADVERTISEMENT',
//         bottomText: 'ADVERTISING SOLUTION',
//         subtitle1: 'Design and Production of Commercial Advertisements:',
//         description1: "We create visually appealing advertisements tailored to our clients' needs, ensuring their products and services stand out in the competitive market.",
//         subtitle2: 'Producction of media advertisement:',
//         description2: 'We produce high-quality advertisements for various media platforms, including radio, television, and the internet, ensuring a consistent and powerful ',
//         images: [advertisement1, advertisement2, advertisement3, advertisement4]
//     }, 
// // Graphics and Visual Effects: Adding innovative visual elements and special effects to enhance video appeal.
// // Animation Design: 
// // Creating animations to add appeal to visual content.
//     {
//         id: 3,
//         title: 'Post Production',
//         sideText: 'POST PRODUCTION',
//         bottomText: 'Editing, Color, Visual Effect',
//         subtitle1: 'Editing:',
//         description1: "Editing and arranging filmed and audio materials to produce final videos.",
//         subtitle2: 'Color Grading and Correction:',
//         description2: 'Adjusting and modifying colors and contrast to enhance the final image quality.',
//         images: [postProduction1, postProduction2, postProduction3, postProduction4]
//     },
//     {
//         id: 2,
//         title: 'Visual Content',
//         sideText: 'VISUAL CONTENT',
//         bottomText: 'CREATING VISUAL CONTENT',
//         subtitle1: 'Creating Innovative and Engaging Visual Content:',
//         description1: "Our team of creative professionals develops unique visual content that reflects the essence of our clients' brands and engages their target audience.",
//         subtitle2: 'Promotional Video Production:',
//         description2: "We produce compelling promotional videos that effectively convey our clients' messages and increase engagement. Our visual content is created to the highest standards of quality and creativity.",
//         images: [visualContent1, visualContent2, visualContent3, visualContent4]
//     }
// ]

import AdvertisementIcon from '../AdvertisementIconSvg';

export const services = [
    {
        id: 1,
        title: 'Production',
        sideText: 'PRODUCTION',
        bottomText: 'DESIGN & PRODUCTION',
        subtitle1: 'Expert Video Production Services:',
        description1: "We offer comprehensive video production services that capture your brand's essence and communicate your message effectively to your audience.",
        subtitle2: 'Professional Videography Solutions:',
        description2: "Our skilled videographers produce high-quality videos tailored to your marketing needs, ensuring impactful visual content that engages viewers.",
        thirdImageText: "Design & Production Videography",
        images: [production1, production2, production33, production44]
    },
    {
        id: 2,
        title: 'Advertisement',
        sideText: 'ADVERTISEMENT',
        bottomText: 'ADVERTISING SOLUTION',
        subtitle1: 'Advertisement Design:',
        description1: "We design eye-catching advertisements that highlight your products and services, making them stand out in the competitive market.",
        subtitle2: 'Media Advertisement Production:',
        description2: "Our team produces high-quality advertisements for various media platforms, ensuring your message reaches a wide audience effectively.",
        thirdImageText: "Comprehensive Advertising Solutions",
        images: [advertisement1, advertisement2, advertisement33, advertisement44]
    },
    {
        id: 3,
        title: 'Post Production',
        sideText: 'POST PRODUCTION',
        bottomText: 'Editing, Color, Visual Effect',
        subtitle1: 'Advanced Editing Services:',
        description1: "Our editing team refines and arranges your footage to create polished, professional videos that captivate and inform your audience.",
        subtitle2: 'Color Grading and Effects:',
        description2: "We enhance your videos with expert color grading and visual effects, delivering a final product that meets the highest standards of quality.",
        thirdImageText: "Editing, Coloring, (VFX & CGI) Visual Effect, Animation",
        images: [postProduction1, postProduction2, postProduction33, postProduction44]
    },
    {
        id: 4,
        title: 'Visual Content',
        sideText: 'VISUAL CONTENT',
        bottomText: 'CREATING VISUAL CONTENT',
        subtitle1: 'Creating Innovative and Engaging Visual Content:',
        description1: "Our team of creative professionals develops unique visual content that reflects the essence of our clients' brands and engages their target audience.",
        subtitle2: 'Promotional Video Production:',
        description2: "We produce compelling promotional videos that effectively convey our clients' messages and increase engagement. Our visual content is created to the highest standards of quality and creativity.",
        thirdImageText: "Creating Innovative and Engaging Visual Content",
        images: [visualContent1, visualContent2, visualContent33, visualContent44]
    }
]
