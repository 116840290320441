import React, { useState } from 'react';

const VisualContentIcon = ({ divClassName }) => {
  const [svgColor1, setSvgColor1] = useState('#464646'); // Initial color

  const handleMouseEnter1 = () => {
    setSvgColor1('#e5493a'); // Change color on hover (example: red)
  };

  const handleMouseLeave1 = () => {
    setSvgColor1('#464646'); // Reset color on mouse leave
  };

  return (
    <div className={divClassName}>
        <svg 
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 229.6 230.28"
        style={{ enableBackground: 'new 0 0 229.6 230.28', width: '99%', height: '99%' }}
        xmlSpace="preserve"
        // onMouseEnter={handleMouseEnter1}
        // onMouseLeave={handleMouseLeave1}
        >
        <style type="text/css">
          {`
            .st0{fill:${svgColor1};transition:all 0.3s;}
          `}
        </style>
        <g>
        <path className="st0" d="M8.9,157.4c-4.8-12.6-7.3-26.2-7.3-40.3c0-31.7,12.9-60.5,33.8-81.3C56.2,15.1,85.1,2.2,117,2.2
		c13.7,0,27.2,2.4,39.8,7c12.4,4.5,24,11.1,34.3,19.8c1.6,1.4,2,3.1,0.6,4.9c-1.1,1.4-3,2-4.6,0.9c-9.8-8.3-20.9-14.7-32.7-19
		c-11.9-4.3-24.5-6.6-37.4-6.6C87,9.2,59.9,21.3,40.3,40.8C20.7,60.3,8.5,87.3,8.5,117.1c0,13.4,2.4,26.1,6.9,37.9
		c4.6,12.2,11.4,23.4,19.9,33.1c1.3,1.2,0.7,3.6-0.6,4.7c-1.3,1.1-3.5,1.2-4.7-0.1C20.9,182.3,13.7,170.4,8.9,157.4L8.9,157.4z"/>
	<path className="st0" d="M203.3,41.3c9,10.3,16.2,22.2,21.1,35.2c4.8,12.6,7.3,26.2,7.3,40.4c0,31.7-12.9,60.4-33.8,81.2
		s-49.8,33.6-81.6,33.6c-13.7,0-27.2-2.4-39.8-7s-24.4-11.4-34.9-20.2v0c-0.7-0.6-1.2-1.5-1.2-2.6c0-0.9,0.4-1.8,1-2.4
		c1.3-1.3,3.4-1.3,4.6-0.3l0,0c9.8,8.3,20.9,14.7,32.8,19c11.8,4.3,24.5,6.6,37.4,6.6c29.9,0,57.1-12.1,76.7-31.6
		c19.6-19.5,31.8-46.5,31.8-76.3c0-13.3-2.4-26.1-6.9-37.9c-4.6-12.1-11.3-23.3-19.9-33.1c-1.3-1.5-0.7-3.9,0.9-5
		C200.4,40,202.1,40.1,203.3,41.3L203.3,41.3z"/>
	<path className="st0" d="M159.1,55.4h15.5c1.3,0,2.5,0.5,3.4,1.4v0c0.9,0.8,1.4,2.1,1.4,3.4v121.5c0,1.3-0.5,2.5-1.4,3.4h0
		c-0.9,0.9-2.1,1.4-3.4,1.4h-15.5c-1.3,0-2.5-0.5-3.4-1.4l0,0c-0.9-0.9-1.4-2.1-1.4-3.4v-18.2H79.9c-0.3,0.1-0.6,0-0.8,0v18.3h66.2
		c5.2-0.1,3.6,5.4,0.1,5H75c-0.3,0.1-0.5,0.1-0.8,0.1H58.8c-1.3,0-2.5-0.6-3.4-1.4h0c-0.8-0.9-1.4-2.1-1.4-3.4V60.2
		c0-1.3,0.5-2.5,1.4-3.4c0.9-0.8,2.1-1.4,3.4-1.4h15.5c1.3,0,2.6,0.5,3.4,1.4v0c0.9,0.8,1.4,2.1,1.4,3.4v17.1c0.3,0,0.6-0.1,0.9-0.1
		h74.3l0,0V60.4H89.9c-3.8,0.4-5.6-5,0.1-5h68.5C158.8,55.4,158.9,55.4,159.1,55.4L159.1,55.4z M130,121.8l-9.6,5.6h0l-12.5,7.2
		v-28.8l25,14.4L130,121.8z M118.5,124.1L118.5,124.1l6.8-3.9l-13.7-7.9V128L118.5,124.1z M163.5,168.8h3.9v4.1h3.9v3.9h-7.8v-0.2
		v-3.7V168.8z M163.5,153.9h3.9v4.1h3.9v3.9h-7.8v-0.2V158V153.9z M163.5,139h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7V139z M163.5,124h3.9
		v4.1h3.9v3.9h-7.8v-0.2v-3.7V124z M163.5,109.1h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7V109.1z M163.5,94.2h3.9v4.1h3.9v3.9h-7.8V102v-3.7
		V94.2z M163.5,63.7h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7V63.7z M163.5,79.3h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7V79.3z M63.4,168.8h3.9v4.1
		h3.9v3.9h-7.8v-0.2v-3.7C63.4,172.9,63.4,168.8,63.4,168.8z M63.4,153.9h3.9v4.1h3.9v3.9h-7.8v-0.2V158
		C63.4,158,63.4,153.9,63.4,153.9z M63.4,139h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7C63.4,143.1,63.4,139,63.4,139z M63.4,124h3.9v4.1h3.9
		v3.9h-7.8v-0.2v-3.7C63.4,128.2,63.4,124,63.4,124z M63.4,109.1h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7C63.4,113.2,63.4,109.1,63.4,109.1z
		 M63.4,94.2h3.9v4.1h3.9v3.9h-7.8V102v-3.7C63.4,98.3,63.4,94.2,63.4,94.2z M63.4,63.7h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7
		C63.4,67.8,63.4,63.7,63.4,63.7z M63.4,79.3h3.9v4.1h3.9v3.9h-7.8v-0.2v-3.7C63.4,83.4,63.4,79.3,63.4,79.3z M79.1,158.6
		c0.3,0,0.6-0.1,0.9-0.1h74.3l0,0v-6.2H79.9c-0.3,0-0.6,0-0.8,0V158.6z M79.1,147.4c0.3,0,0.6-0.1,0.9-0.1h74.3l0,0V92.9H79.9
		c-0.3,0-0.6,0-0.8,0V147.4z M79.1,88c0.3,0,0.6-0.1,0.9-0.1h74.3l0,0v-5.8H79.9c-0.3,0.1-0.6,0-0.8,0V88z M74.1,60.3H58.9v121.4h12
		h3.2V60.3z M174.5,60.4h-15.2v121.2h15.2V60.4z"/>
        </g>
        </svg>
    </div>
  );
};

export default VisualContentIcon;