import '../../assets/css/loader.css';

export default function Loader() {
    return (
        <>
            <div className='my-loader-container'>
                <div className="loading-container">
                    <div className="loading-text">
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                    </div>
                </div>
            </div>
        </>
    )
}