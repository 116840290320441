import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Fade } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import logo3 from '../../assets/images/logo3.png';
import toggleIcon from '../../assets/images/toggle-icon.png';
import '../../assets/css/header.css';

import { useScrollContext } from '../ScrollProvider';
import { useLoading } from '../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import { to } from '@react-spring/web';

function Header() {
    const { i18n, t } = useTranslation();
    const navbarRef = useRef(null);
    const [navbarBg, setNavbarBg] = useState('');
    const [showElements, setShowElements] = useState(false);
    const [toggleButtonImage, setToggleButtonImage] = useState(toggleIcon);
    const [isRotated, setIsRotated] = useState(false);
    const [activeLink, setActiveLink] = useState('');

    const [timeoutId, setTimeoutId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { setScrollTo } = useScrollContext();

    const { isLoading } = useLoading();
    // const { setScrollTo: setScrollContext } = useScrollContext();

    const handleToggleButton = () => {
        // Clear any existing timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setIsRotated(!isRotated);
        setShowElements(!showElements);

        if (!isRotated) {
            // Set a timeout to reset the rotation after 5 seconds
            const id = setTimeout(() => {
                setIsRotated(false);
                setShowElements(false);
            }, 5000);

            setTimeoutId(id);
        }
    };

    const handleMobileToggleButton = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setIsRotated(!isRotated);

        const togglerIcon = document.getElementsByClassName('navbar-toggler')[0]
        
        if (!isRotated) {
            // Set a timeout to reset the rotation after 5 seconds
            const id = setTimeout(() => {
                setIsRotated(false);
                togglerIcon.click();
            }, 50000000);

            setTimeoutId(id);
        }
    }

    // const handleToggleButton = () => {
    //     setIsRotated(!isRotated);
    //     setShowElements(!showElements);
    // };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 75) {
                setNavbarBg('navbar-custom-background');
            } else {
                setNavbarBg('');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const sections = document.querySelectorAll('section');

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setActiveLink(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 }
        );

        sections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);

    // const scrollToSection = (id) => {
    //     const section = document.getElementById(id);
    //     if (section) {
    //         section.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    // const scrollToSection = (id) => {

    //     if (location.pathname !== '/') {
    //         navigate('/');
    //         setScrollTo(id);
    //     } else {
    //         const section = document.getElementById(id);
    //         if (section) {
    //             section.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // };

    const scrollToSection = (id) => {
        if (location.pathname !== '/') {
            navigate('/');
            setScrollTo(id);
        } else {
            const section = document.getElementById(id);
            if (section) {
                const sectionRect = section.getBoundingClientRect();
                const offset = 90;
                const scrollToPosition = sectionRect.top + window.scrollY - offset;
                window.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth'
                });
                // section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    useEffect(() => {
        if (!isLoading) {
            const hash = location.hash;
            if (hash) {
                const id = hash.substring(1);
                scrollToSection(id);
            }
        }
    }, [location.hash])
    


    return (
        <div>
            <Navbar ref={navbarRef} className={navbarBg} bg="transparent" expand="lg" fixed="top" style={{ marginTop: '3%' }}>
                <Container fluid>
                    <Navbar.Brand href="/" className="ms-5">
                        <div>
                            <img width={'140px'} src={logo3} alt={'logo'} />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"
                        className={` ${isRotated ? 'rotate-back' : 'rotate-90'}`}                                   
                        onClick={() => handleMobileToggleButton()}
                    />
                    <Navbar.Collapse id="navbarScroll" className={i18n.language === 'ar' ? 'navbar-collapse-ar' : ''}>
                        <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                            <div className="mb-1 mobile-links" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <Link to={'/#hero'} className={`text-white nav-link mt-md-0 mt-1 me-md-0 me-3 pt-1 pb-1 ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'}`} onClick={() => scrollToSection('hero')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.home')}
                                </Link>
                                <Link to={'/#about'} className={`text-white nav-link mt-md-0 mt-1 me-md-0 me-3 pt-1 pb-1 ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'}`} onClick={() => scrollToSection('about')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.about')}
                                </Link>
                                <Link to={'/#work'} className={`text-white nav-link mt-md-0 mt-1 me-md-0 me-3 pt-1 pb-1 ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'}`} onClick={() => scrollToSection('work')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.work')}
                                </Link>
                                <Link to={'/#services'} className={`text-white nav-link mt-md-0 mt-1 me-md-0 me-3 pt-1 pb-1 ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'}`} onClick={() => scrollToSection('services')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.services')}
                                </Link>
                                <Link to={'/#contact'} className={`text-white nav-link mt-md-0 mt-1 me-md-0 me-3 pt-1 pb-1 ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'}`} onClick={() => scrollToSection('contact')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.contact')}
                                </Link>
                                <Dropdown className={`text-white me-3 mb-1 pb-1 text-decoration-none ${i18n.language == 'ar' ? 'my-header-link-ar' : 'my-header-link'}`}>
                                    <Dropdown.Toggle
                                        style={i18n.language === 'ar' ? { padding: 0, background: 'transparent', border: 0, verticalAlign: 'top', fontSize: '1.1em', letterSpacing: 0 } : { padding: 0, background: 'transparent', border: 0, verticalAlign: 'top', fontSize: '1.1em' }}
                                        id="dropdown-basic"
                                        className={`text-white text-decoration-none ${i18n.language == 'ar' ? 'my-header-link-ar' : 'my-header-link'}`}
                                    >
                                        {/* {currentLng == 'en-US' || currentLng == 'en-GB' || currentLng == 'en' ? 'English' : 'German'} */}
                                        {t('header.languages.title')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ background: 'rgba(0, 0, 0, 0.7)', minWidth: '90px', maxWidth: '90px' }}>
                                        <Dropdown.Item
                                            className="language-dropdown-item"
                                            style={{ color: 'white', width: '90px', textAlign: 'center' }}
                                            onClick={() => changeLanguage('en')}
                                        >
                                            {t('header.languages.english')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="language-dropdown-item"
                                            style={{ color: 'white', width: '90px', textAlign: 'center' }}
                                            onClick={() => changeLanguage('ar')}
                                        >
                                            {t('header.languages.arabic')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Nav>
                        <Fade in={showElements}>
                            <div className="text-white wide-screen-links d-flex me-5">
                                <Link to={'/#hero'} className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar': 'my-header-link'} me-5 text-decoration-none ${activeLink === 'hero' ? 'header-active-link' : ''}`} onClick={() => scrollToSection('hero')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.home')}
                                </Link>
                                <Link to={'/#services'} className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link' } me-5 text-decoration-none ${activeLink === 'services' ? 'header-active-link' : ''}`} onClick={() => scrollToSection('services')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.services')}
                                </Link>
                                <Link to={'/#about'} className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link' } me-5 text-decoration-none ${activeLink === 'about' ? 'header-active-link' : ''}`} onClick={() => scrollToSection('about')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.about')}
                                </Link>
                                <Link to={'/#work'} className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link' } me-5 text-decoration-none ${activeLink === 'work' ? 'header-active-link' : ''}`} onClick={() => scrollToSection('work')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.work')}
                                </Link>
                                <Link to={'/#contact'} className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link' } me-5 text-decoration-none ${activeLink === 'contact' ? 'header-active-link' : ''}`} onClick={() => scrollToSection('contact')} style={i18n.language == 'ar' ? {letterSpacing: 0} : {}}>
                                    {t('header.links.contact')}
                                </Link>
                                <Dropdown className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'} me-5 text-decoration-none`}>
                                    <Dropdown.Toggle
                                        style={i18n.language === 'ar' ? { padding: 0, background: 'transparent', border: 0, margin: 0, position: 'relative', bottom: '1px', fontSize: '1.1em', letterSpacing: 0} : { padding: 0, background: 'transparent', border: 0, margin: 0, position: 'relative', bottom: '2px', fontSize: '1.1em'}}
                                        id="dropdown-basic"
                                        className={`text-white ${i18n.language === 'ar' ? 'my-header-link-ar' : 'my-header-link'} me-3 text-decoration-none`}
                                    >
                                        {/* {currentLng == 'en-US' || currentLng == 'en-GB' || currentLng == 'en' ? 'English' : 'German'} */}
                                        {t('header.languages.title')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={i18n.language === 'ar' ? { background: 'rgba(0, 0, 0, 0.2)', minWidth: '100px', width: '80%', left: '-20px' } : { background: 'rgba(0, 0, 0, 0.2)', minWidth: '100px', width: '80%' }}>
                                        <Dropdown.Item
                                            className="language-dropdown-item"
                                            style={{ color: 'white', width: '100%', textAlign: 'center' }}
                                            onClick={() => changeLanguage('en')}
                                        >
                                            {t('header.languages.english')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="language-dropdown-item"
                                            style={{ color: 'white', width: '100%', textAlign: 'center' }}
                                            onClick={() => changeLanguage('ar')}
                                        >
                                            {t('header.languages.arabic')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Fade>
                        <Form className="d-flex me-5">
                            <div className="not-mobile-links">
                                <img
                                    style={{ cursor: 'pointer', float: 'right' }}
                                    className={` ${isRotated ? 'rotate-90' : 'rotate-back'}`}
                                    width={'80%'}
                                    height={'auto'}
                                    src={toggleButtonImage}
                                    alt="Toggle Button"
                                    onClick={() => handleToggleButton()}
                                    onMouseOver={() => handleToggleButton()}
                                    onFocus={() => handleToggleButton()}
                                />
                            </div>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;
