import { useEffect, useRef } from 'react';

import '../../assets/css/splitting_line.css';

function SplittingLine() {
    const lineRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const rect = lineRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            if (rect.top <= windowHeight && rect.bottom >= 20) {
                lineRef.current.classList.add('animate-line');
            } else {
                lineRef.current.classList.remove('animate-line');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="split-line">
            <div ref={lineRef} className="line-half red"></div>
            <div className="line-half white"></div>
        </div>
    );
}

export default SplittingLine;