import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import '../../assets/css/service.css';

import production1 from '../../assets/images/production1.png';
import production2 from '../../assets/images/production2.png';
import production3 from '../../assets/images/production3.png';
import production4 from '../../assets/images/production4.png';
import ProductionIconSvg from "../ProductionIconSvg";
import AdvertisementIcon from "../AdvertisementIconSvg";
import PostProductionIcon from "../PostProductionIconSvg";
import VisualContentIcon from "../VisualContentIconSvg";

import { TypeAnimation } from "react-type-animation";
import { useTransition, animated } from '@react-spring/web'

import { useTranslation } from "react-i18next";

function ServiceDetails({ service }) {
    const { i18n, t } = useTranslation();
    const serviceCol1Images = [service.images[0], service.images[1], service.images[2]]
    const serviceCol2Images = [service.images[1], service.images[2], service.images[0]]
    const serviceCol3Images = [service.images[2], service.images[0], service.images[1]]
    const [animationKey, setAnimationKey] = useState(0);

    const [index, setIndex] = useState(0)
    const transitions = useTransition(index, {
        key: index,
        from: { opacity: 0.2 },
        enter: { opacity: 1 },
        leave: { opacity: 0.2 },
        config: { duration: 3000 },
        onRest: (_a, _b, item) => {
          if (index === item) {
            setIndex(state => (state + 1) % serviceCol1Images.length)
          }
        },
        exitBeforeEnter: true,
    })
    function renderProperIcon(title) {
        if (title === "Production")
            return <ProductionIconSvg divClassName={'service-svg-icon service-svg-icon1'} />
        else if (title === "Advertisement")
            return <AdvertisementIcon divClassName={'service-svg-icon service-svg-icon1'} />
        else if (title === "Post Production")
            return <PostProductionIcon divClassName={'service-svg-icon service-svg-icon1'} />
        else
            return <VisualContentIcon divClassName={'service-svg-icon service-svg-icon1'} />
    }

    function renderProperText(title, position) {
        if (title === "Production")
            return t('HomePage.OurServices.service2.' + position)
        else if (title === "Advertisement")
            return t('HomePage.OurServices.service1.' + position)
        else if (title === "Post Production")
            return t('HomePage.OurServices.service3.' + position)
        else
            return t('HomePage.OurServices.service4.' + position)
    }

    function renderSideProperText(title, position) {
        if (title === "Production")
            return t('PRODUCTION')
        else if (title === "Advertisement")
            return t('ADVERTISEMENT')
        else if (title === "Post Production")
            return t('POST PRODUCTION')
        else
            return t('VISUAL CONTENT')
    }

    function renderBottomProperText(title, position) {
        if (title === "Production")
            return t('DESIGN & PRODUCTION')
        else if (title === "Advertisement")
            return t('ADVERTISING SOLUTION')
        else if (title === "Post Production")
            return t('Editing, Color, Visual Effect')
        else
            return t('CREATING VISUAL CONTENT')
    }
    // function renderProperText(title) {
    //     if (title === "Production")
    //         return t('OurServices.service2.text')
    //     else if (title === "Advertisement")
    //         return t('OurServices.service1.text')
    //     else if (title === "Post Production")
    //         return t('OurServices.service3.text')
    //     else
    //         return t('OurServices.service4.text')
    // }

    useEffect(() => {
        setAnimationKey(prevKey => prevKey + 1); 
    }, [i18n.language])

    return (
        <Container dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} fluid id="serviceDetails" style={{ minHeight: '100%', backgroundColor: '#202020', position: 'relative', overflow: "hidden" }}>
            <div className={i18n.language === 'ar'? 'side-text-ar' : 'side-text'}>{renderSideProperText(service.title, 'sideText')}</div>
            <Container>
                <Row className="service-details-main-row d-flex justify-content-center mt-md-3">
                    <Row className="d-flex justify-content-center mt-5 service-details-row" style={{width: '88%'}}>
                        <Col md={6} xs={12} className="p-0">
                            <div className="service-details-line"></div>
                            <h1 className={`${i18n.language === 'ar' ? "service-details-title-ar" : "service-details-title"}`}>{renderProperText(service.title, "title")}</h1>
                            <div className="d-flex flex-column justify-content-center" style={{height: '80%'}}>
                            {/* <div style={{height: '80%' }}> */}
                            <h4 className={`${i18n.language === 'ar' ? "service-details-description-title-ar" : "service-details-description-title"}`}>{renderProperText(service.title, "subtitle1")}</h4>
                            <p className={`${i18n.language == 'ar' ? "service-details-description-ar" : "service-details-description"}`}>{renderProperText(service.title, "description1")}</p>
                            {/* <br/> */}
                            <h4 className={`${i18n.language === 'ar' ? "service-details-description-title-ar" : "service-details-description-title"}`}>{renderProperText(service.title, "subtitle2")}</h4>
                            <p className={`${i18n.language == 'ar' ? "service-details-description-ar" : "service-details-description"}`}>{renderProperText(service.title, "description2")}</p>
                            </div>
                        </Col>
                        {/* <Col md={6} xs={6} className="p-0 custom-service-col first-image-col" style={{backgroundImage: `url(${service.images[0]})`}}>

                        </Col> */}
                        <Col md={6} xs={6} className="p-0 custom-service-col first-image-col">
                            {transitions((style, i) => (
                                <animated.div
                                className="custom-service-col first-image-col"
                                style={{
                                    ...style,
                                    backgroundImage: `url(${serviceCol1Images[i]})`,
                                    willChange: 'opacity'
                                }}
                                />
                            ))}
                        </Col>
                        {/* <Col md={5} className="p-0 custom-col-1 custom-service-col" style={{backgroundImage: `url(${service.images[1]})`}}>
                        <img src={production2} alt="production2" />
                        </Col> */}
                        <Col md={5} className="p-0 custom-col-1 custom-service-col">
                            {transitions((style, i) => (
                                <animated.div
                                style={{
                                    ...style,
                                    backgroundImage: `url(${serviceCol2Images[i]})`,
                                    willChange: 'opacity',
                                    width: "100%",
                                    height: "100%",
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                                />
                            ))}
                        </Col>
                        {/* <Col md={5} className="p-0 custom-col-2 custom-service-col" style={{backgroundImage: `url(${service.images[2]})`}}>
                            <div className="third-image-text">{service.thirdImageText}</div>
                            <div className="third-image-text-line mt-2"></div>
                        </Col> */}
                        <Col md={5} className="p-0 custom-col-2 custom-service-col">
                            {transitions((style, i) => (
                                <animated.div
                                className="Hatem"
                                style={{
                                    ...style,
                                    backgroundImage: `url(${serviceCol3Images[i]})`,
                                    willChange: 'opacity',
                                    width: "100%",
                                    height: "100%",
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative'
                                }}
                                >
                                    <div className={i18n.language === 'ar' ? 'third-image-text-ar' : 'third-image-text'}>
                                        {/* <TypeAnimation
                                            sequence={[
                                            // Same substring at the start will only be typed out once, initially
                                            service.thirdImageText,
                                            150
                                            ]}
                                            wrapper="span"
                                            speed={10}
                                            repeat={Infinity}
                                        /> */}
                                        {renderProperText(service.title, 'thirdImageText')}
                                    </div>
                                    <div className={i18n.language === 'ar' ? 'third-image-text-ar-line mt-2' : 'third-image-text-line mt-2'}></div>
                                </animated.div>
                            ))}
                        </Col>
                        <Col md={2} className="p-0 custom-col-3 custom-service-col d-flex justify-content-center align-items-center">
                            {/* <AdvertisementIcon divClassName={'service-svg-icon'} /> */}
                            {renderProperIcon(service.title)}
                        {/* <img src={production4} alt="production4" />  */}
                        </Col>
                        <div className="service-details-bottom-title" dir="ltr">
                            <TypeAnimation
                                    key={animationKey}
                                    sequence={[
                                    // Same substring at the start will only be typed out once, initially
                                    renderBottomProperText(service.title, 'bottomText'),
                                    1500,
                                    '',
                                    1500,
                                    renderBottomProperText(service.title, 'bottomText'),
                                    1500
                                    ]}
                                    wrapper="span"
                                    speed={10}
                                    repeat={Infinity}
                                />
                        </div>
                    </Row>
                </Row>
                {/* <Row className="d-flex justify-content-center">
                    <Row className="d-flex justify-content-center service-details-row" style={{width: '88%'}}>
                        <Col md={5} className="p-0 custom-col-1 custom-service-col" style={{height: '275px', backgroundImage: `url(${service.images[1]})`}}>
                        </Col>
                        <Col md={5} className="p-0 custom-col-2 custom-service-col" style={{height: '275px', backgroundImage: `url(${service.images[2]})`}}>
             
                            <div className="third-image-text">{service.thirdImageText}</div>
                            <div className="third-image-text-line mt-2"></div>
                        </Col>
                        <Col md={2} className="p-0 custom-col-3 custom-service-col d-flex justify-content-center align-items-center">
           
                            {renderProperIcon(service.title)}
                   
                        </Col>
                        <div className="service-details-bottom-title">{service.bottomText}</div>
                    </Row>
                </Row> */}
            </Container>
        </Container>
    );
}

export default ServiceDetails;