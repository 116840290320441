import React, { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import facebook from '../../assets/images/facebook-icon.png';
import twitter from '../../assets/images/x-icon.png';
import youtube from '../../assets/images/youtube-icon.png';
import instagram from '../../assets/images/instagram-icon.png';
import tiktok from '../../assets/images/tiktok-logo.png';
import followUsImage from '../../assets/images/get-in-touch-bg.jpg';
import addressIcon from '../../assets/images/address-icon.png';
import phoneIcon from '../../assets/images/phone-icon.png';
import emailIcon from '../../assets/images/email-icon.png';



import '../../assets/css/follow_us.css';



function ContactInfo({contactInfoData}) {
    const { i18n, t } = useTranslation();
    const [socialName, setSocialName] = useState(t('HomePage.GetInTouch.socialMedia'));

    const [contactInfoBg, setContactInfoBg] = useState(followUsImage)

    const handleMouseOver = (text) => {
        setSocialName(text);
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Ensure the input is a string
        phoneNumber = phoneNumber.toString();
      
        // Use a regular expression to format the phone number
        return phoneNumber.replace(/(\+\d{3})(\d{5})(\d{4})/, '$1 $2 $3');
    };

    useEffect(() => {
        if (window.innerWidth < 768 && contactInfoData.bg_mobile)
            setContactInfoBg(contactInfoData.bg_mobile)
        else if (window.innerWidth >= 768 && contactInfoData.bg)
            setContactInfoBg(contactInfoData.bg)
    }, [])

    useEffect(() => {
        setSocialName(t('HomePage.GetInTouch.socialMedia'))
    }, [i18n.language])
    return (
        <div style={{ overflowX: 'hidden' }}>
            <Container className="follow-us" fluid style={{ backgroundImage: `url(${contactInfoBg})`, minHeight: '500px', color: 'white' }}>
                <Row className='d-flex justify-content-center m-0'>
                    <Col className='text-center mt-5 justify-content-center'>
                        <h1 className={i18n.language === 'ar' ? 'section-title-ar' : 'section-title'} style={{ color: 'rgba(255, 255, 255, 0.9)' }}>{t('HomePage.GetInTouch.title')}</h1>
                        <h6 className={i18n.language === 'ar' ? 'section-description-ar' : 'section-description'} style={{color: 'rgba(255, 255, 255, 0.9)'}}>{t('HomePage.GetInTouch.slogen')}</h6>
                        <div className="d-flex justify-content-center">
                            <div style={{ width: '75px', height: '2px', borderBottom: '1px solid rgba(255, 255, 255, 0.9)' }}></div>
                        </div>
                    </Col>
                </Row>
                <Container dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                    <Row className='d-flex justify-content-center' style={{ marginTop: '35px'}}>
                    <Row className="d-flex justify-content-center mt-md-5 mt-2 contact-info-row">
                        <Col xs={6} md className="d-flex text-cetner justify-content-center p-0 first-contact-info-col">
                            <div className='mt-3 mb-3 text-center' data-aos="zoom-in" data-aos-duration="1000">
                                <h4 className={i18n.language === 'ar' ? 'get-in-touch-title-ar' : 'get-in-touch-title'}>{t('HomePage.GetInTouch.address.title')}</h4>
                                <p className={i18n.language === 'ar' ? 'get-in-touch-description-ar' : 'get-in-touch-description'}>{t('HomePage.GetInTouch.address.slogen')}</p>
                                <div className='d-flex justify-content-center'>
                                    <div className="contact-info-icon d-flex justify-content-center">
                                        <a href={contactInfoData.address_link ? contactInfoData.address_link : 'https://maps.app.goo.gl/oEvb9NJZFwB8KLMs8'} target="_blank" rel="noopener noreferrer"><img src={addressIcon} alt="address" /></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md  className="d-flex text-cetner justify-content-center p-0">
                            <div className='mt-3 mb-3 text-center' data-aos="zoom-in" data-aos-duration="1000"> 
                                <h4 className={i18n.language === 'ar' ? 'get-in-touch-title-ar' : 'get-in-touch-title'}>{t('HomePage.GetInTouch.phone.title')}</h4>
                                {/* <p className='get-in-touch-description'>{t('HomePage.GetInTouch.phone.slogen')}</p> */}
                                <p className={i18n.language === 'ar' ? 'get-in-touch-description-ar' : 'get-in-touch-description'} dir={'ltr'}>{contactInfoData.phone ? formatPhoneNumber(contactInfoData.phone) : t('HomePage.GetInTouch.phone.slogen')}</p>
                                <div className='d-flex justify-content-center'>
                                    <div className="contact-info-icon d-flex justify-content-center">
                                        <a href={contactInfoData.phone ? `tel:${contactInfoData.phone}` : 'tel:+971508717479'} target="_blank" rel="noopener noreferrer"><img src={phoneIcon} alt="address" /></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md className="d-flex text-cetner justify-content-center p-0">
                            <div className='mt-3 mb-3 text-center' data-aos="zoom-in" data-aos-duration="1000">
                                <h4 className={i18n.language === 'ar' ? 'get-in-touch-title-ar' : 'get-in-touch-title'}>{t('HomePage.GetInTouch.whatsapp.title')}</h4>
                                <p className={i18n.language === 'ar' ? 'get-in-touch-description-ar' : 'get-in-touch-description'}>{t('HomePage.GetInTouch.whatsapp.slogen')}</p>
                                <div className='d-flex justify-content-center'>
                                    <div className="contact-info-icon d-flex justify-content-center">
                                        <a href={contactInfoData.whatsapp ? `https://wa.me/${contactInfoData.whatsapp}` : 'https://wa.me/+971508717479'} target="_blank" rel="noopener noreferrer"><i className="bi bi-whatsapp bootstrap-icon"></i></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md className="d-flex text-cetner justify-content-center p-0">
                            <div className='mt-3 mb-3 text-center' data-aos="zoom-in" data-aos-duration="1000">
                                <h4 className={i18n.language === 'ar' ? 'get-in-touch-title-ar' : 'get-in-touch-title'}>{t('HomePage.GetInTouch.email')}</h4>
                                <p className={i18n.language === 'ar' ? 'get-in-touch-description-ar' : 'get-in-touch-description'}>info@mvm.art</p>
                                <div className='d-flex justify-content-center'>
                                    <div className="contact-info-icon d-flex justify-content-center">
                                        <a href="mailto:info@mvm.art" target="_blank" rel="noopener noreferrer"><img width={'23px'} src={emailIcon} alt="address" /></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md className="d-flex text-cetner justify-content-center p-0">
                            <div className='mt-3 mb-3 text-center' data-aos="zoom-in" data-aos-duration="1000">
                                <h4 className={i18n.language === 'ar' ? 'get-in-touch-title-ar' : 'get-in-touch-title'}>{t('HomePage.GetInTouch.followUs.title')}</h4>
                                <p className={i18n.language === 'ar' ? 'get-in-touch-description-ar' : 'get-in-touch-description'}>{t('HomePage.GetInTouch.followUs.slogen')} {socialName}</p>
                                <div className='d-flex justify-content-center'>
                                    <div className="contact-info-icon d-flex justify-content-center text-center">
                                        <a href="https://www.facebook.com/profile.php?id=61562733960359&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" onMouseOver={() => handleMouseOver('FACEBOOK')}><img width={'10px'} src={facebook} alt="facebook" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center">
                                        <a href="https://x.com/mvmedia_art" target="_blank" rel="noopener noreferrer" onMouseOver={() => handleMouseOver('X')}><img width={'17px'} src={twitter} alt="X" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center">
                                        <a href="https://www.instagram.com/mvm.art.company/?next=%2F" target="_blank" onMouseOver={() => handleMouseOver('INSTAGRAM')}><img width={'20px'} src={instagram} alt="instagram" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center">
                                        <a href="https://www.youtube.com/@MVMArtCompany" target="_blank" onMouseOver={() => handleMouseOver('YOUTUBE')}><img width={'23px'} src={youtube} alt="youtube" /></a>
                                    </div>
                                    <div className="contact-info-icon d-flex justify-content-center text-center">
                                        <a href="https://www.tiktok.com/@mvmedia_art" target="_blank" onMouseOver={() => handleMouseOver('TIKTOK')}><img width={'18px'} src={tiktok} alt="tiktok" /></a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="privacy-policy" dir={'ltr'}>
                            <p style={{ fontSize: '16px', color: '#7e7e7e' }}>{'Privacy Policy | Terms of Service'}</p>
                            <p style={{ fontSize: '17px' }}>&copy;{' MVM 2024'}</p>
                        </div>
                    </Row>
                    </Row>
                </Container>
            </Container>
        </div>
    );
}

export default ContactInfo;
