import React, { useState } from 'react';

const AdvertisementIcon = ({ divClassName }) => {
  const [svgColor, setSvgColor1] = useState('#464646'); // Initial color


  return (
    <div className={divClassName}>
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 229.6 230.28"
        style={{ enableBackground: 'new 0 0 229.6 230.28', width: '99%', height: '99%' }}
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
            .st0{fill:${svgColor};transition:all 0.3s;}
	          .st1{fill-rule:evenodd;clip-rule:evenodd;fill:${svgColor};transition:all 0.3s;}
          `}
        </style>
        <g>
          <path className="st0" d="M8.9,157.2c-4.8-12.6-7.3-26.2-7.3-40.3c0-31.7,12.9-60.5,33.8-81.3C56.2,14.8,85.1,1.9,116.9,1.9
            c13.7,0,27.2,2.4,39.8,7c12.4,4.5,24,11.1,34.3,19.8c1.6,1.4,2,3.1,0.6,4.9c-1.1,1.4-3,2-4.6,0.9c-9.8-8.3-20.9-14.7-32.7-19
            c-11.9-4.3-24.5-6.6-37.4-6.6C87,8.9,59.9,21,40.3,40.6C20.6,60.1,8.5,87,8.5,116.8c0,13.3,2.4,26.1,6.9,37.9
            c4.6,12.2,11.4,23.4,19.9,33.1c1.3,1.2,0.7,3.6-0.6,4.7c-1.3,1.1-3.5,1.2-4.7-0.1C20.9,182,13.7,170.1,8.9,157.2L8.9,157.2z"/>
          <path className="st0" d="M203.3,41.1c9,10.3,16.2,22.2,21.1,35.2c4.8,12.6,7.3,26.2,7.3,40.4c0,31.7-12.9,60.4-33.8,81.2
            s-49.8,33.6-81.6,33.6c-13.7,0-27.2-2.4-39.8-7c-12.6-4.6-24.4-11.4-34.9-20.2v0c-0.7-0.6-1.2-1.5-1.2-2.6c0-0.9,0.4-1.8,1-2.4
            c1.3-1.3,3.4-1.3,4.6-0.3l0,0c9.8,8.3,20.9,14.7,32.8,19c11.8,4.3,24.5,6.6,37.4,6.6c29.9,0,57.1-12.1,76.7-31.6
            c19.6-19.5,31.8-46.5,31.8-76.3c0-13.3-2.4-26.1-6.9-37.9c-4.6-12.1-11.3-23.3-19.9-33.1c-1.3-1.5-0.7-3.9,0.9-5
            C200.4,39.8,202.1,39.9,203.3,41.1L203.3,41.1z"/>
          <path className="st1" d="M102.9,154.4L102.9,154.4l-0.1-0.2l-0.1,0.1l-0.1-0.1v0.1l-0.1,0c-2.3-2.5-17.4-17.3-17.7-19.3
            c0.1-0.4,0.2-1.8,0.3-2l17.5-0.1h0h0h0l17.6,0.1c0.1,0.2,0.2,1.7,0.2,2C120.4,137.1,105.3,152,102.9,154.4L102.9,154.4z
            M102.8,164.6c0,0.2,0.1,0.3,0.1,0.2c0,0,19.3,20.9,20.1,21.5c1.7,1.2,3.3,1.4,4.5,0.1c1.1-1.1,1-2.4,0.4-3.6l-20.3-23.2l23.6-26.6
            c4.6-0.1,13.9-0.1,18.1-0.1c2.2,0,1.8-1.8,1.9-3.2c0.1-0.9,0.1-1.8,0.1-2.8c0-1.3-0.4-11.3-0.2-11.7c0.5-0.7,10.4,1.8,8.7-4.9
            c-1.5-2.7-5-2.5-10.2-2.1c-3.1,0.2-4.4,1.1-4.9,4.1c-0.3,1.5,0.1,14.6,0.1,14.6l-7.3,0.1l-0.1-80.5c-0.3-1.7-2.1-2.4-3.6-2.4
            c-1.6,0-2.9,1.5-2.9,2.4c0,2.5,0,11.6,0,11.6c-0.4,0.9-0.8,0.9-1.5,1c0,0-24.8,0.2-26.7,0.2l0,0c-1.8,0-26.7-0.2-26.7-0.2
            c-0.7-0.1-1.1-0.1-1.5-1c0,0,0-9.1,0-11.6c0-0.9-1.3-2.4-2.9-2.4c-1.5-0.1-3.3,0.7-3.6,2.4l-0.1,80.5l-7.3-0.1
            c0,0,0.3-13.1,0.1-14.6c-0.5-2.9-1.8-3.9-4.9-4.1c-5.2-0.3-8.7-0.5-10.2,2.1c-1.7,6.7,8.2,4.1,8.7,4.9c0.3,0.4-0.2,10.4-0.2,11.7
            c0,1,0,1.9,0.1,2.8c0.1,1.4-0.2,3.2,1.9,3.2c4.1,0,13.5,0,18.1,0.1L98,159.7l-20.3,23.2c-0.6,1.2-0.7,2.5,0.4,3.6
            c1.3,1.3,2.9,1.1,4.6-0.1c0.7-0.5,20-21.5,20-21.5C102.7,164.9,102.8,164.8,102.8,164.6L102.8,164.6z M102.8,64.9
            c12.1,0,24.2,0.2,28.2,0.3c0,0-0.1,9.3-0.1,13.8c0,3.2,0.1,5.9,0,6c-0.3,0.7-0.8,1-1.9,1.1H76.6c-1.1-0.1-1.6-0.3-1.9-1.1
            c-0.1-0.2,0-2.9,0-6c0.1-4.4-0.1-13.8-0.1-13.8C78.5,65.2,90.7,65,102.8,64.9L102.8,64.9z M102.8,92.8l28.1,0.1l0.1,20.1l-28.1,0
            h-0.2l-28.1,0l0.1-20.1L102.8,92.8z M102.8,119.6c7.2,0,17.6,0.1,26.4-0.1c1.1,0,1.8,0.4,1.8,1.2c-0.1,3.1,0,4.7-0.1,5.6
            c-0.1,0.8-3,0.6-5.6,0.6l-22.4,0l0,0h-0.1h-0.1l0,0l-22.4,0c-2.6,0-5.5,0.2-5.6-0.6c-0.1-0.9,0-2.5-0.1-5.6c0-0.7,0.7-1.2,1.8-1.2
            C85.2,119.7,95.6,119.6,102.8,119.6L102.8,119.6z"/>
          <path className="st1" d="M163.8,155.9c3.3,0,6.8,0,10,0l5.6,25.4l-10.8,0.1l-10.8-0.1C158.3,178.5,163.8,155.9,163.8,155.9L163.8,155.9
            z M164.9,149.2c-0.2-0.1,0.1-11.3,0.1-14c0.4-4.6,6.8-4.6,7.1,0c0,2.7,0.6,14,0.6,14C170.3,149.2,167.3,149.2,164.9,149.2
            L164.9,149.2z M158.8,133c-0.4,2-0.6,16.2-0.6,16.2s-9.1,0-10.6,0.1c-3.8,0.3-3.9,5.6-0.4,6.5c0.9,0.2,10,0.1,10.1,0.1
            c-1.4,5.1-7.1,28.7-6.8,30.4c0.3,0.8,36,0,36,0c-0.1-1.1-7.8-34-7.8-34c-1.1-7.6-0.3-13.6-1.3-19.1
            C175.9,124.5,160.4,124.4,158.8,133L158.8,133z"/>
        </g>
      </svg>
    </div>
  );
};

export default AdvertisementIcon;