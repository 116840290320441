import React, { useState } from 'react';

const ProductionIconSvg = ({ divClassName }) => {
  const [svgColor1, setSvgColor1] = useState('#464646'); // Initial color

  const handleMouseEnter1 = () => {
    setSvgColor1('#e5493a'); // Change color on hover (example: red)
  };

  const handleMouseLeave1 = () => {
    setSvgColor1('#464646'); // Reset color on mouse leave
  };

  return (
    <div className={ divClassName }>
        <svg 
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 229.6 230.28"
        style={{ enableBackground: 'new 0 0 229.6 230.28', width: '99%', height: '99%' }}
        xmlSpace="preserve"
        // onMouseEnter={handleMouseEnter1}
        // onMouseLeave={handleMouseLeave1}
        >
        <style type="text/css">
          {`
            .st0{fill:${svgColor1};transition:all 0.3s;}
	          .st1{fill-rule:evenodd;clip-rule:evenodd;fill:${svgColor1};transition:all 0.3s;}
          `}
        </style>
        <g>
              <path className="st0" d="M8.9,157.2c-4.8-12.6-7.3-26.2-7.3-40.3c0-31.7,12.9-60.5,33.8-81.3C56.2,14.8,85.1,1.9,116.9,1.9
          c13.7,0,27.2,2.4,39.8,7c12.4,4.5,24,11.1,34.3,19.8c1.6,1.4,2,3.1,0.6,4.9c-1.1,1.4-3,2-4.6,0.9c-9.8-8.3-20.9-14.7-32.7-19
          c-11.9-4.3-24.5-6.6-37.4-6.6C87,8.9,59.9,21,40.3,40.6C20.6,60.1,8.5,87,8.5,116.8c0,13.3,2.4,26.1,6.9,37.9
          c4.6,12.2,11.4,23.4,19.9,33.1c1.3,1.2,0.7,3.6-0.6,4.7c-1.3,1.1-3.5,1.2-4.7-0.1C20.9,182,13.7,170.1,8.9,157.2L8.9,157.2z"/>
        <path className="st0" d="M203.3,41.1c9,10.3,16.2,22.2,21.1,35.2c4.8,12.6,7.3,26.2,7.3,40.4c0,31.7-12.9,60.4-33.8,81.2
          s-49.8,33.6-81.6,33.6c-13.7,0-27.2-2.4-39.8-7c-12.6-4.6-24.4-11.4-34.9-20.2v0c-0.7-0.6-1.2-1.5-1.2-2.6c0-0.9,0.4-1.8,1-2.4
          c1.3-1.3,3.4-1.3,4.6-0.3l0,0c9.8,8.3,20.9,14.7,32.8,19c11.8,4.3,24.5,6.6,37.4,6.6c29.9,0,57.1-12.1,76.7-31.6
          c19.6-19.5,31.8-46.5,31.8-76.3c0-13.3-2.4-26.1-6.9-37.9c-4.6-12.1-11.3-23.3-19.9-33.1c-1.3-1.5-0.7-3.9,0.9-5
          C200.4,39.8,202.1,39.9,203.3,41.1L203.3,41.1z"/>
        <path className="st1" d="M89.3,52.3c-5.5,0-10.4,2.2-14,5.8c-3.6,3.6-5.8,8.5-5.8,14c0,5.5,2.2,10.4,5.8,14c2.6,2.6,5.9,4.5,9.6,5.3
          h8.8c3.7-0.8,7-2.7,9.6-5.3c3.6-3.6,5.8-8.5,5.8-14s-2.2-10.4-5.8-14C99.7,54.5,94.8,52.3,89.3,52.3L89.3,52.3z M110.4,154.7
          l-0.7,29.8c0.4,2.7,3.8,3.5,5,0.1l0.6-29.4l32.5,31.5c3.3,1.6,5.1-1.4,3.5-3.6l-33-32h36.5c1.6,0,3.1-0.6,4.1-1.7h0
          c1.1-1.1,1.7-2.5,1.7-4.1v-7.2l21.9,8.1c0.4,0.1,0.8,0.2,1.2,0.2h0c1,0,1.8-0.4,2.5-1l0,0c0.5-0.4,0.9-1,1.1-1.6
          c0.1-0.4,0.3-0.9,0.3-1.4l0.1-41.9c0-0.5-0.1-1-0.2-1.4c-0.2-0.6-0.6-1.2-1.1-1.6v0c-0.6-0.6-1.5-1-2.5-1l0,0
          c-0.4,0-0.8,0.1-1.2,0.2l-22,8v-7.2c0-1.6-0.6-3.1-1.7-4.1c-1.1-1.1-2.5-1.7-4.1-1.7h-2.8c0.4-0.3,0.8-0.7,1.1-1.1
          c4.7-4.7,7.6-11.1,7.6-18.3c0-7.1-2.9-13.6-7.6-18.2c-4.7-4.7-11.1-7.6-18.3-7.6c-7.1,0-13.6,2.9-18.2,7.6
          c-1.8,1.8-3.4,3.9-4.6,6.2c-1.2-2.3-2.8-4.4-4.6-6.2c-4.7-4.7-11.1-7.6-18.3-7.6c-7.1,0-13.6,2.9-18.2,7.6
          c-4.7,4.7-7.6,11.1-7.6,18.2c0,7.1,2.9,13.6,7.6,18.3c0.4,0.4,0.7,0.7,1.1,1.1h-1.8c-1.6,0-3.1,0.7-4.1,1.7v0
          c-1.1,1.1-1.7,2.5-1.7,4.1v7.5H49.1c-1.1,0-2.1,0.4-2.9,1.2c-0.7,0.7-1.2,1.7-1.2,2.9v23.7c0,1.1,0.5,2.1,1.2,2.9
          c0.7,0.7,1.8,1.2,2.9,1.2h15.5v8.7c0,1.6,0.7,3.1,1.7,4.1c1.1,1.1,2.5,1.7,4.1,1.7H107l-33,32c-1.6,2.2,0.2,5.1,3.5,3.6
          L110.4,154.7z M78.8,102.8h11.8c2.9,0.8,3.3,5.2,0,6h-9.8V120c-0.7,2.6-5.1,2.9-6,0v-13.1c0-1.1,0.4-2.1,1.2-2.9
          C76.7,103.2,77.7,102.8,78.8,102.8L78.8,102.8z M181.7,103.2l-21,7.7v20.8l20.9,7.7L181.7,103.2z M64.6,110.8H51v19.8h13.6
          C64.6,130.5,64.6,110.8,64.6,110.8z M139.3,91.5c3.7-0.8,7-2.7,9.6-5.3c3.6-3.6,5.8-8.5,5.8-14s-2.2-10.4-5.8-14
          c-3.6-3.6-8.5-5.8-14-5.8s-10.4,2.2-14,5.8c-3.6,3.6-5.8,8.5-5.8,14s2.2,10.4,5.8,14c2.6,2.6,5.9,4.5,9.6,5.3H139.3z M117.8,91.5
          c-0.4-0.3-0.8-0.7-1.1-1.1c-1.8-1.8-3.4-3.9-4.6-6.2c-1.2,2.3-2.8,4.3-4.6,6.2c-0.4,0.4-0.7,0.7-1.1,1.1H117.8z M154.6,97.4h-84
          v11.4v23.7v12.6h84v-12v-23.7V97.4z M134.5,58.3c3.8,0,7.3,1.6,9.8,4c2.5,2.5,4.1,6,4.1,9.7c0,3.8-1.6,7.3-4.1,9.8
          c-2.5,2.5-5.9,4-9.8,4c-3.8,0-7.3-1.5-9.8-4c-2.5-2.5-4.1-5.9-4.1-9.8c0-3.8,1.5-7.3,4.1-9.8C127.2,59.9,130.7,58.3,134.5,58.3
          L134.5,58.3z M139.3,67.3c-1.2-1.2-2.9-2-4.8-2s-3.6,0.8-4.8,2c-1.2,1.2-2,2.9-2,4.8c0,1.9,0.8,3.6,2,4.8c1.2,1.2,2.9,2,4.8,2
          s3.6-0.8,4.8-2c1.2-1.2,2-2.9,2-4.8C141.3,70.2,140.5,68.5,139.3,67.3L139.3,67.3z M89.3,58.3c3.8,0,7.3,1.6,9.8,4
          c2.5,2.5,4.1,6,4.1,9.7c0,3.8-1.6,7.3-4.1,9.8c-2.5,2.5-5.9,4-9.8,4c-3.8,0-7.3-1.5-9.8-4c-2.5-2.5-4.1-5.9-4.1-9.8
          c0-3.8,1.6-7.3,4.1-9.8C82,59.9,85.4,58.3,89.3,58.3L89.3,58.3z M94.1,67.3c-1.2-1.2-2.9-2-4.8-2s-3.6,0.8-4.8,2
          c-1.2,1.2-2,2.9-2,4.8c0,1.9,0.8,3.6,2,4.8c1.2,1.2,2.9,2,4.8,2s3.6-0.8,4.8-2c1.2-1.2,2-2.9,2-4.8C96.1,70.2,95.3,68.5,94.1,67.3
          L94.1,67.3z"/>
        </g>
        </svg>
    </div>
  );
};

export default ProductionIconSvg;