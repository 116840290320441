import { useEffect } from 'react';
// import '../assets/css/test.css';

export default function TestSvg() {
    useEffect(() => {
        const pos = document.documentElement;
        const handleMouseMove = (e) => {
            pos.style.setProperty('--x', e.clientX + 'px');
            pos.style.setProperty('--y', e.clientY + 'px');
        };
        pos.addEventListener('mousemove', handleMouseMove);
        return () => {
            pos.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <>
            <section>
                <h2 id="hatem">LIGHT</h2>
            </section>
            <div className='light'></div>
        </>
    );  
}
