import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ComingSoon from './views/comingSoon/ComingSoon';
import HomePage from './views/homePage/HomePage';
import GalleryPage from './views/galleryPage/GalleryPage';
import TestSvg from './views/TestSvg';
import Test1 from './views/Test1';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from './views/layout/Loader';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './views/layout/Header';
import { useLoading } from './contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import './App.css';

function App() {
  // const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { isLoading, setIsLoading } = useLoading();
  const {i18n} = useTranslation();


  useEffect(() => {
    // Show loader when route changes
    setIsLoading(true);


    // Hide loader when content is rendered
    const handleRouteChange = () => {
        setIsLoading(false);
    };

    // Mimic a delay for loading (e.g., fetching data)
    const timeoutId = setTimeout(handleRouteChange, 2700); // Adjust the delay as needed

    return () => {
        clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  AOS.init();
  return (
    <>
    {/* {isLoading && <Loader />} */}
    {(!isLoading || isLoading) && (
          <div>
              <Routes>
                  {/* Define your routes here */}
                  <Route path="/" element={<HomePage />} />
                  <Route path='/work' element={<GalleryPage />} />
                  <Route path='/test' element={<TestSvg />} />
                  <Route path='/test1' element={<Test1 />} />
                  <Route path="/loader" element={<Loader />} />
                  {/* Add more routes as needed */}
              </Routes>
          </div>
    )}
    </>
  );
}

export default App;
